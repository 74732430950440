import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars-2';
import { ListItemButton } from '@mui/material';


export const SiteSurveyContainer = styled.div`
    padding: 0px 20px;
    padding-top: 10px;
    && .MuiStepLabel-label {
        margin-top: 10px !important;
        margin-bottom: 13px;
    }
`;

export const SiteSurveyContentStyle = styled.div`
    padding: 10px 20px;

    && .MuiStepLabel-label {
        margin-top: 10px !important;
        margin-bottom: 13px;
    }
`;

export const UiScrollbarsSiteSurvey = styled(Scrollbars)`
    height: auto !important;
    max-height: calc(100vh - 270px) !important;

    & > div:first-child {
        max-height: calc(100vh - 270px) !important;
    }

    border-bottom: thin solid #c0c0c0;
`;

export const ListItemCustom = styled(ListItemButton)<{ isActive: boolean }>`
    border-radius: 4px !important;
    cursor: default !important;
    margin-top: 10px;
    background-color: #ebebeb !important;
`;
