import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { SiteSurveyViewProps } from './SiteSurvey.type';
import { SiteSurveyContainer } from './SiteSurvey.style';
// import { ItrackConfigLogForm } from '../ItrackConfigLog/ItrackConfigLog.form';
import StepOne from './StepOne/StepOne';
import { Loading } from '../../helpers/Loading';
import StepTwo from './StepTwo/StepTwo';
import { SurveyStepState } from '../../states/component/Survey';
import { useRecoilState } from 'recoil';
import StepThree from './StepThree/StepThree';
import StepFour from './StepFour/StepFour';

const steps = [
    'General Information', 
    'Network Information', 
    'Fleet Information', 
    'Tyre Information', 
    'Site Configuration'
];

const SiteSurveyContent: React.FC<SiteSurveyViewProps> = ({ 
    loadingData, 
    handleSubmit 
}) => {
    const [activeStep, setActiveStep] = useRecoilState(SurveyStepState);
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    if (loadingData) return <Loading />;

    return (
        <SiteSurveyContainer>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {activeStep === 0 && (
                <StepOne 
                    activeStep={activeStep}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            )}

            {activeStep === 1 && (
                <StepTwo 
                    activeStep={activeStep}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            )}

            {activeStep === 2 && (
                <StepThree 
                    activeStep={activeStep}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            )}

            {activeStep === 3 && (
                <StepFour 
                    activeStep={activeStep}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            )}
        </SiteSurveyContainer>
    );
};

export default SiteSurveyContent;