import styled from 'styled-components';
import { ThemeType } from '../../../../../styled/theme.type';

export const SubTableView = styled.div`
    
`;

export const ContainerSubTable = styled.div`
    box-shadow: ${({ theme }: ThemeType) =>
        theme.name === 'Dark' ? '#161c24 0px 5px 17px 3px inset' : 'inset 0 3px 20px -9px #737373'};
    padding: 13px;
    background: ${({ theme }: ThemeType) => (theme.name === 'Dark' ? '#2f3740' : '#f1f1f1')};
`;