import React from 'react';
import * as style from './Survey.style';
import { Paper } from '@mui/material';
import Survey from '../../components/Survey/Survey';
// import module

export const SurveyContent: React.FC = (): JSX.Element => (
    <style.SurveyContent data-testid='SurveyContent'>
        <Paper className='cardPageContainer' elevation={1}>
            <Survey />
        </Paper>
    </style.SurveyContent>
);