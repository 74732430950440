import React, { useEffect, useState } from 'react';
import Wrapper from '../../../helpers/wrapper';
import { SiteSurveyStepOneForm, StepOneProps } from './StepOne.type';
import { StepOneContent } from './StepOne.view';
import { TimeZoneResponseType, TimeZoneType } from '../../../models/ApiTimeZone.type';
import ApiTimeZone from '../../../api/ApiTimeZone';
import { OrderAlphabetically } from '../../../helpers/converter';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
// import module

const apiTimeZone = new ApiTimeZone();

const StepOne: React.FC<StepOneProps> = ({
    activeStep,
    handleNext,
    handleBack,
    handleSubmit
}): JSX.Element => {
    const surveyData  = useRecoilValue(SurveyState);
    const [timeZones, setTimeZones] = useState<TimeZoneType[]>([]);
    
    const dataStepOne: SiteSurveyStepOneForm = {
        mineOwner: checkSurvey(surveyData, 'mineOwner'),
        location: checkSurvey(surveyData, 'location'),
        startDate: checkSurvey(surveyData, 'startDate'),
        siteName: checkSurvey(surveyData, 'siteName'),
        timeZone: checkSurvey(surveyData, 'timeZone'),
        surveyDate: checkSurvey(surveyData, 'surveyDate'),
        shippingAttention: checkSurvey(surveyData, 'shippingAttention'),
        shippingEmail: checkSurvey(surveyData, 'shippingEmail'),
        shippingTelephone: checkSurvey(surveyData, 'shippingTelephone'),
        shippingAddress: checkSurvey(surveyData, 'shippingAddress'),
        customerAttention: checkSurvey(surveyData, 'customerAttention'),
        customerEmail: checkSurvey(surveyData, 'customerEmail'),
        customerTelephone: checkSurvey(surveyData, 'customerTelephone'),
        customerAddress: checkSurvey(surveyData, 'customerAddress'),
        projectCoordinatorName: checkSurvey(surveyData, 'projectCoordinatorName'),
        projectCoordinatorEmail: checkSurvey(surveyData, 'projectCoordinatorEmail'),
        projectCoordinatorTelephone: checkSurvey(surveyData, 'projectCoordinatorTelephone'),
        mineITName: checkSurvey(surveyData, 'mineITName'),
        mineITEmail: checkSurvey(surveyData, 'mineITEmail'),
        mineITTelephone: checkSurvey(surveyData, 'mineITTelephone'),
        mineDispatchName: checkSurvey(surveyData, 'mineDispatchName'),
        mineDispatchEmail: checkSurvey(surveyData, 'mineDispatchEmail'),
        mineDispatchTelephone: checkSurvey(surveyData, 'mineDispatchTelephone'),
        tyreProviderName: checkSurvey(surveyData, 'tyreProviderName'),
        tyreProviderEmail: checkSurvey(surveyData, 'tyreProviderEmail'),
        tyreProviderTelephone: checkSurvey(surveyData, 'tyreProviderTelephone')
    };

    const getSelect = async () => {
        const { items }: TimeZoneResponseType = await apiTimeZone.getTimeZoneCodeBookPublic();
        items && setTimeZones(OrderAlphabetically(items, 'timezoneName'));
    };

    useEffect(() => {
        getSelect();
    }, []);

    return (
        <StepOneContent 
            data-testid='StepOne-testid'
            activeStep={activeStep}
            surveyData={surveyData}
            valuesInitForm={dataStepOne}
            timeZones={timeZones}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(StepOne);