import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { LocationType } from '../models/ApiLocationType.type';

export default class ApiLocationType extends PortalApi<LocationType> {
    route = 'stock/location-type';

    getLocationTypeCodeBook = async (): Promise<LocationType[]> => {
        return this.getCodeBook('');
    };

    getWithPagination = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        extendUrl = ''
    ): Promise<any> => {
        return await this.getTable<any>(
            extendUrl,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };
    
}
