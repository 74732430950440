import React from 'react';
import { SiteSurveyStepOneForm, StepOneViewProps } from './StepOne.type';
import { UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AutocompleteElement, InputElement } from '../../FormElements';
import Translator from '../../../states/global/Translator';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
// import module

export const StepOneContent: React.FC<StepOneViewProps> = ({
    // surveyData,
    valuesInitForm,
    timeZones,
    activeStep,
    handleNext,
    handleSubmit
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const validationSchema = Yup.object().shape({
        mineOwner: Yup.string().required(Trans('messages.p.this_field_is_required')),
        location: Yup.string().required(Trans('messages.p.this_field_is_required')),
        startDate: Yup.string(),
        siteName: Yup.string(),
        timeZone: Yup.string(),
        surveyDate: Yup.string(),
        shippingAttention: Yup.string(),
        shippingEmail: Yup.string().email('Invalid email'),
        shippingTelephone: Yup.string(),
        shippingAddress: Yup.string(),
        customerAttention: Yup.string(),
        customerEmail: Yup.string().email('Invalid email'),
        customerTelephone: Yup.string(),
        customerAddress: Yup.string(),
        projectCoordinatorName: Yup.string(),
        projectCoordinatorEmail: Yup.string().email('Invalid email'),
        projectCoordinatorTelephone: Yup.string(),
        mineITName: Yup.string(),
        mineITEmail: Yup.string().email('Invalid email'),
        mineITTelephone: Yup.string(),
        mineDispatchName: Yup.string(),
        mineDispatchEmail: Yup.string().email('Invalid email'),
        mineDispatchTelephone: Yup.string(),
        tyreProviderName: Yup.string(),
        tyreProviderEmail: Yup.string().email('Invalid email'),
        tyreProviderTelephone: Yup.string()
    });

    // const validationSchema = Yup.object().shape({
    //     mineOwner: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     location: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     startDate: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     siteName: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     timeZone: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     surveyDate: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     shippingAttention: Yup.string(),
    //     shippingEmail: Yup.string().email('Invalid email'),
    //     shippingTelephone: Yup.string(),
    //     shippingAddress: Yup.string(),
    //     customerAttention: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     customerEmail: Yup.string().email('Invalid email').required(Trans('messages.p.this_field_is_required')),
    //     customerTelephone: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     customerAddress: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     projectCoordinatorName: Yup.string(),
    //     projectCoordinatorEmail: Yup.string().email('Invalid email'),
    //     projectCoordinatorTelephone: Yup.string(),
    //     mineITName: Yup.string(),
    //     mineITEmail: Yup.string().email('Invalid email'),
    //     mineITTelephone: Yup.string(),
    //     mineDispatchName: Yup.string(),
    //     mineDispatchEmail: Yup.string().email('Invalid email'),
    //     mineDispatchTelephone: Yup.string(),
    //     tyreProviderName: Yup.string(),
    //     tyreProviderEmail: Yup.string().email('Invalid email'),
    //     tyreProviderTelephone: Yup.string()
    // });

    return (
        <Formik
            initialValues={valuesInitForm}
            onSubmit={(values: SiteSurveyStepOneForm, actions) => {
                handleSubmit(values, 1, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
        >
            {(propsF: FormikProps<SiteSurveyStepOneForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm
                    // resetForm
                } = propsF;
                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            <Box>
                                <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                                    {/* SITE INFORMATION && SHIPPING ADDRESS (FOR STOCK LOCATION) */}
                                    <Grid item md={6}>
                                        {/* SITE INFORMATION */}
                                        <Accordion defaultExpanded sx={{ bgcolor: '#f5f5f5d1' }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant='subtitle1'>
                                                    SITE INFORMATION
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                                    <InputElement
                                                        label={Trans('messages.t.mine_owen')}
                                                        name='mineOwner'
                                                        value={values.mineOwner}
                                                        error={errors.mineOwner}
                                                        touched={touched.mineOwner}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        fullWidth
                                                    />
                                                    <InputElement
                                                        label={Trans('messages.t.location')}
                                                        name='location'
                                                        value={values.location}
                                                        error={errors.location}
                                                        touched={touched.location}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        fullWidth
                                                    />
                                                    <Grid item xs={12} md={6}>
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterMoment}
                                                        >
                                                            <MobileDateTimePicker
                                                                label={
                                                                    <span className='fieldRequired'>
                                                                        <i>*</i> {Trans('messages.t.start_date')}
                                                                    </span>
                                                                }
                                                                disableFuture={false}
                                                                value={values.startDate}
                                                                disablePast={true}
                                                                onChange={(newValue) => {
                                                                    setFieldValue(
                                                                        'startDate',
                                                                        newValue ?
                                                                            moment(newValue, 'YYYY-MM-DD')
                                                                                .format('YYYY-MM-DD')
                                                                            : ''
                                                                    );
                                                                }}
                                                                mask='____-__-__'
                                                                inputFormat='YYYY-MM-DD'
                                                                views={['year', 'month', 'day']}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        name='startDate'
                                                                        size='small'
                                                                        fullWidth
                                                                        helperText={
                                                                            params.error
                                                                                ? errors.startDate
                                                                                    ? errors.startDate
                                                                                    : errors.startDate
                                                                                : ''
                                                                        }
                                                                        error={!!errors.startDate}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <InputElement
                                                        label={Trans('messages.t.site_name')}
                                                        name='siteName'
                                                        value={values.siteName}
                                                        error={errors.siteName}
                                                        touched={touched.siteName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        fullWidth
                                                    />
                                                    <AutocompleteElement
                                                        label={Trans('messages.t.timezone')}
                                                        name='timeZone'
                                                        size='small'
                                                        options={timeZones ?? []}
                                                        value={values.timeZone}
                                                        defaultValue={timeZones.find(
                                                            option => option.id === +values.timeZone
                                                        )}
                                                        error={errors.timeZone}
                                                        touched={touched.timeZone}
                                                        onBlur={handleBlur}
                                                        setFieldValue={setFieldValue}
                                                        optionsPropertyLabel='timezoneName'
                                                        optionsPropertyValue='id'
                                                        required={true}
                                                        lg={6}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    />
                                                    <Grid item xs={12} md={6}>
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterMoment}
                                                        >
                                                            <MobileDateTimePicker
                                                                label={
                                                                    <span className='fieldRequired'>
                                                                        <i>*</i> {Trans('messages.t.survey_date')}
                                                                    </span>
                                                                }
                                                                disableFuture={false}
                                                                value={values.surveyDate}
                                                                disablePast={true}
                                                                onChange={(newValue) => {
                                                                    setFieldValue(
                                                                        'surveyDate',
                                                                        newValue ?
                                                                            moment(newValue, 'YYYY-MM-DD')
                                                                                .format('YYYY-MM-DD')
                                                                            : ''
                                                                    );
                                                                }}
                                                                mask='____-__-__'
                                                                inputFormat='YYYY-MM-DD'
                                                                views={['year', 'month', 'day']}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        name='surveyDate'
                                                                        size='small'
                                                                        fullWidth
                                                                        helperText={
                                                                            params.error
                                                                                ? errors.surveyDate
                                                                                    ? errors.surveyDate
                                                                                    : errors.surveyDate
                                                                                : ''
                                                                        }
                                                                        error={!!errors.surveyDate}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>

                                        {/* SHIPPING ADDRESS (FOR STOCK LOCATION) */}
                                        <Accordion defaultExpanded sx={{ bgcolor: '#f5f5f5d1' }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant='subtitle1'>
                                                    SHIPPING ADDRESS (FOR STOCK LOCATION)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                                    <InputElement
                                                        label={Trans('messages.t.shipping_attention')}
                                                        name='shippingAttention'
                                                        value={values.shippingAttention}
                                                        error={errors.shippingAttention}
                                                        touched={touched.shippingAttention}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={false}
                                                        fullWidth
                                                    />
                                                    <InputElement
                                                        label={Trans('messages.t.shipping_email')}
                                                        name='shippingEmail'
                                                        value={values.shippingEmail}
                                                        error={errors.shippingEmail}
                                                        touched={touched.shippingEmail}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={false}
                                                        fullWidth
                                                    />
                                                    <InputElement
                                                        label={Trans('messages.t.shipping_telephone')}
                                                        name='shippingTelephone'
                                                        value={values.shippingTelephone}
                                                        error={errors.shippingTelephone}
                                                        touched={touched.shippingTelephone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={false}
                                                        fullWidth
                                                    />
                                                    <InputElement
                                                        label={Trans('messages.t.shipping_address')}
                                                        name='shippingAddress'
                                                        value={values.shippingAddress}
                                                        error={errors.shippingAddress}
                                                        touched={touched.shippingAddress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        rows={3}
                                                        multiline
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    />
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    {/* CUSTOMER ADDRESS (FOR CUSTOMS CLEARANCE) */}
                                    <Grid item md={6}>
                                        <Accordion defaultExpanded sx={{ bgcolor: '#f5f5f5d1' }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant='subtitle1'>
                                                    CUSTOMER ADDRESS (FOR CUSTOMS CLEARANCE)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                                    <InputElement
                                                        label={Trans('messages.t.customer_name')}
                                                        name='customerAttention'
                                                        value={values.customerAttention}
                                                        error={errors.customerAttention}
                                                        touched={touched.customerAttention}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        fullWidth
                                                    />
                                                    <InputElement
                                                        label={Trans('messages.t.customer_email')}
                                                        name='customerEmail'
                                                        value={values.customerEmail}
                                                        error={errors.customerEmail}
                                                        touched={touched.customerEmail}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        fullWidth
                                                    />
                                                    <InputElement
                                                        label={Trans('messages.t.customer_telephone')}
                                                        name='customerTelephone'
                                                        value={values.customerTelephone}
                                                        error={errors.customerTelephone}
                                                        touched={touched.customerTelephone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        fullWidth
                                                    />
                                                    <InputElement
                                                        label={Trans('messages.t.customer_address')}
                                                        name='customerAddress'
                                                        value={values.customerAddress}
                                                        error={errors.customerAddress}
                                                        touched={touched.customerAddress}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        rows={3}
                                                        multiline
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    />
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    {/* CONTACT INFORMATION */}
                                    <Grid item md={12}>
                                        <Accordion defaultExpanded sx={{ bgcolor: '#f5f5f5d1' }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant='subtitle1'>
                                                    CONTACT INFORMATION
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {[
                                                    {
                                                        title: 'project_coordinator',
                                                        fields: [
                                                            {
                                                                label: 'name',
                                                                value: 'projectCoordinatorName'
                                                            },
                                                            {
                                                                label: 'email',
                                                                value: 'projectCoordinatorEmail'
                                                            },
                                                            {
                                                                label: 'telephone',
                                                                value: 'projectCoordinatorTelephone'
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        title: 'mine_it',
                                                        fields: [
                                                            {
                                                                label: 'name',
                                                                value: 'mineITName'
                                                            },
                                                            {
                                                                label: 'email',
                                                                value: 'mineITEmail'
                                                            },
                                                            {
                                                                label: 'telephone',
                                                                value: 'mineITTelephone'
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        title: 'mine_dispatch',
                                                        fields: [
                                                            {
                                                                label: 'name',
                                                                value: 'mineDispatchName'
                                                            },
                                                            {
                                                                label: 'email',
                                                                value: 'mineDispatchEmail'
                                                            },
                                                            {
                                                                label: 'telephone',
                                                                value: 'mineDispatchTelephone'
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        title: 'tyre_provider',
                                                        fields: [
                                                            {
                                                                label: 'name',
                                                                value: 'tyreProviderName'
                                                            },
                                                            {
                                                                label: 'email',
                                                                value: 'tyreProviderEmail'
                                                            },
                                                            {
                                                                label: 'telephone',
                                                                value: 'tyreProviderTelephone'
                                                            }
                                                        ]
                                                    }
                                                ].map((group) => (
                                                    <Box key={group.title} sx={{ mb: 2 }}>
                                                        <Typography variant='subtitle2' sx={{ mb:2 }} fontWeight='bold'>
                                                            {Trans(`messages.t.${group.title}`)}
                                                        </Typography>
                                                        <Grid container spacing={2}>
                                                            {group.fields.map((field) => (
                                                                <InputElement
                                                                    key={field.value}
                                                                    label={Trans(`messages.t.${field.label}`)}
                                                                    name={field.value}
                                                                    value={values[field.value]}
                                                                    error={errors[field.value]}
                                                                    touched={touched[field.value]}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={false}
                                                                    fullWidth
                                                                    md={4}
                                                                    lg={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                />
                                                            ))}
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Box>
                        </UiScrollbarsSiteSurvey>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pb: 2 }}>
                            {activeStep < 4 && (
                                <Button 
                                    onClick={handleNext} 
                                    sx={{ mr: 1 }} 
                                    variant='contained'
                                    size='small'
                                    disabled={
                                        Object.keys(touched).length === 0 || 
                                        Object.keys(touched).length > 0 && Object.keys(errors).length > 0
                                    }
                                >
                                    Next
                                </Button>
                            )}
                            <Button 
                                onClick={() => {
                                    submitForm();
                                }} 
                                sx={{ mr: 1 }} 
                                variant='contained'
                                size='small'
                                disabled={isSubmitting}
                            >
                                {activeStep === 4 ? 'Finish' : 'Save'} 
                            </Button>
                        </Box>
                    </>
                );
            }}
        </Formik>
    );
};