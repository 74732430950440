import { IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import * as type from './ShippingOrderDetails.type';
import * as view from './ShippingOrderDetails.view';
import * as ShippingOrderState from '../../../states/component/ShippingOrder';
import Translator from '../../../states/global/Translator';
import BoxItems from '../../BoxItems/BoxItems';
import { ShippingOrder } from '../../../../src/types/common';
import ImagePopup from '../../ImagePopup/ImagePopup';
import ApiShippingStatusHistory from '../../../api/ApiShippingStatusHistory';
import ApiSku from '../../../api/ApiSku';
import { getFullScreenModal } from '../../../helpers/getFullScreenModal';
import { FromUTCToTimezone } from '../../../helpers/Converters';
import { TextEllipsis, TitleItemWithIcon } from '../../../styled/global.style';
import { ItemsType } from '../../Box/Box.type';
import { StockItemsType } from '../../../models/ApiItems.type';
// import module

const api = new ApiShippingStatusHistory();
const apiSku = new ApiSku();

const ShippingOrderDetails: React.FC<type.ShippingOrderDetailsProps> = (props) => {
    const Trans = useRecoilValue(Translator);
    const [shippingOrderDetails, setshippingOrderDetails] = useState<ShippingOrder | null>(null);
    const [boxId, setBoxId] = useState<number>();
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const [modalItems, setModalItems] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [statusHistory, setStatusHistory] = useRecoilState(ShippingOrderState.StatusHistory);
    const formschemaNewStatus = Yup.object().shape({
        status: Yup.string().required(Trans('messages.p.this_field_is_required')),
        comment: Yup.string().required(Trans('messages.p.this_field_is_required'))
    });
    const [reshippedItems, setReshippedItems] = useState<{ invoiceNumber: string, childrenId: number }[]>([]);

    const getItemsReship = (shippingOrder): { invoiceNumber: string, childrenId: number }[] => {
        const getItemReship: { invoiceNumber: string, childrenId: number }[] = [];

        shippingOrder.childrenShippingOrder.map((child) => {
            if (child?.shippingOrderItem && child?.shippingOrderItem.length) {
                child.shippingOrderItem.forEach((item) => {
                    getItemReship.push({ invoiceNumber: child.saleOrderId, childrenId: item?.item?.id });
                });
            }
        });

        return getItemReship;
    };

    const getDatas = useCallback(async () => {
        setLoading(true);
        const { data }: any = await api.getGeneral(
            `stock/shipping-order-status-history/shipping-order/${props.shippingOrderId}`
        );

        const {
            data: { shippingOrder }
        }: any = await api.getGeneral(`stock/shipping-order/${props.shippingOrderId}`);

        if (!shippingOrder) {
            throw shippingOrder;
        }

        shippingOrder.items = shippingOrder.shippingOrderItem;
        setshippingOrderDetails({ shippingOrder: shippingOrder });
        setStatusHistory(data.length ? data : []);
        setLoading(false);
        setReshippedItems(getItemsReship(shippingOrder));


    }, [setshippingOrderDetails, setStatusHistory, props.shippingOrderId]);

    const getItems = async (id) => {
        setBoxId(id);
        setModalItems(true);
    };

    const resetListStatus = (): void => setStatusHistory([]);

    const formatItem = (row): string => {
        switch (row.sku.skuType.type) {
            case ItemsType.GENERIC_ITEM:
                return row.sku?.name ? row.sku.name : '---';
            case ItemsType.BOX:
                // eslint-disable-next-line max-len
                return `${Trans('messages.t.box_invoice')}: ${shippingOrderDetails?.shippingOrder?.saleOrderId} (${row.boxItemsQuantity})`;
            case ItemsType.SENSOR:
                return row.sku?.name ? row.sku.name : 'Sensor';
            case ItemsType.TABLET:
                return row.sku?.name ? row.sku.name : 'Tablet';
            case ItemsType.HUB:
                return row.sku?.name + ' [ID:' + (row.itrackDeviceId || row.itemId) + ']';
            default:
                return row.sku.skuType.type;
        }
    };

    const columnsItemsSending = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original?.item?.id ?? '---',
            Export: ({ row }: CellProps) => row.original?.item?.id ?? '---'
        },
        {
            Header: Trans('messages.t.image'),
            accessor: 'sku.image',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            exportType: 'image',
            Export: ({ row }: CellProps) =>
                row.original?.item?.sku?.itemImageS3Key ? apiSku.getImageUrl(row.original.item.sku) : '',
            Cell: ({ row: { original } }: CellProps) =>
                original?.item?.sku.skuType.type !== ItemsType.BOX ? (
                    <ImagePopup
                        imageUrl={apiSku.getImageUrl(original?.item?.sku)}
                        thumbnailUrl={apiSku.getImageUrl(original?.item?.sku)}
                        height='20px'
                    />
                ) : (
                    ''
                ),
            minWidth: 55,
            maxWidth: 90
        },
        {
            Header: Trans('messages.t.item'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'item.sku.skuType.type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            minWidth: 180,
            // maxWidth: 110,
            Cell: ({ row: { original } }: CellProps) =>
                original?.item && original.item.sku.skuType.type === ItemsType.BOX ? (
                    <TitleItemWithIcon>
                        {`${formatItem(original?.item)} `}
                        <IconButton
                            size='small'
                            className='info'
                            style={{ minWidth: 'auto' }}
                            title={Trans('messages.t.items')}
                            id={`showItems${original.id}`}
                            onClick={() => getItems(original?.item.id)}
                        >
                            <Search />
                        </IconButton>
                    </TitleItemWithIcon>
                ) : original.item && original.item.sku.skuType.type === ItemsType.HUB ? (
                    <TextEllipsis title={formatItem(original?.item)} className={!original.item.version ? 'danger' : ''}>
                        {formatItem(original.item)}{' '}
                        {original.item.version
                            ? Trans('messages.t.revision') + ' ' + original.item.version
                            : Trans('messages.t.no_revision')}
                    </TextEllipsis>
                ) : original.item ? (
                    <TextEllipsis title={formatItem(original?.item)}>{formatItem(original.item)}</TextEllipsis>
                ) : (
                    '---'
                )
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'item.serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 140,
            minWidth: 140,
            Cell: ({ row }: CellProps) => {
                return row.original?.item
                    ? row.original?.item.sku.skuType.type !== ItemsType.BOX
                        ? row.original?.item?.serialNumber
                        : row.original?.item?.itemAttribute
                            ? row.original?.item.itemAttribute[0].value
                            : ' '
                    : '---';
            }
        },
        {
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => {
                return row.original?.item
                    ? row.original?.item.sku.skuType.type === ItemsType.GENERIC_ITEM
                        ? row.original?.quantity
                        : '---'
                    : '---';
            },
            minWidth: 75,
            maxWidth: 80
        },
        {
            Header: Trans('messages.t.reshipped'),
            accessor: 'reshipped',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => {
                let value: string | null = '---';
                let child: StockItemsType | null = null;
                if (
                    shippingOrderDetails?.shippingOrder?.childrenShippingOrder &&
                    shippingOrderDetails?.shippingOrder?.childrenShippingOrder.length
                ) {
                    shippingOrderDetails?.shippingOrder?.childrenShippingOrder.map((itemOrder) => {
                        if (itemOrder?.shippingOrderItem && itemOrder?.shippingOrderItem.length) {
                            child = itemOrder.shippingOrderItem.find(
                                child => child?.item?.id === row.original?.item?.id
                            );

                            if (child && Object.keys(child).length) {
                                value = itemOrder.saleOrderId;
                            }
                        } else {
                            return itemOrder;
                        }
                    });
                }

                return value;
            }
        },
        {
            Header: Trans('messages.t.comment'),
            accessor: 'comment',
            disableGroupBy: true,
            aggregate: 'comment',
            canFilters: true,
            width: 130,
            minWidth: 130,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.comment}> {row.original.comment} </TextEllipsis>
            )
        }
    ];

    const columnsChangeStatus = [
        {
            Header: Trans('messages.t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            width: 140,
            canFilters: true,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false)
        },
        {
            Header: Trans('messages.t.status'),
            accessor: 'shippingOrderStatus.status',
            disableGroupBy: true,
            aggregate: 'count',
            width: 80,
            canFilters: true,
            Cell: ({ row }: CellProps) =>
                Trans('messages.t.' + row.original.shippingOrderStatus.status.replaceAll(' ', '_').toLowerCase())
        },
        {
            Header: Trans('messages.t.comment'),
            accessor: 'comment',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => row.original.comment
        }
    ];

    useEffect(() => {
        !statusHistory.length && getDatas();
        return () => {
            setStatusHistory([]);
        };
    }, [getDatas]);

    return (
        <>
            <BoxItems
                BoxId={boxId}
                modalItems={modalItems}
                setModalItems={setModalItems}
                setBoxId={setBoxId}
                typeModal='drawer'
                reshippedItems={reshippedItems}
            />

            <view.ShippingOrderDetailsContent
                data-testid='ShippingOrderDetails-testid'
                fullScreenModal={getFullScreenModal()}
                columns={columnsItemsSending}
                columnsChangeStatus={columnsChangeStatus}
                handleSubmit={() => false}
                formschema={formschemaNewStatus}
                shippingOrderDetails={shippingOrderDetails?.shippingOrder}
                resetListStatus={resetListStatus}
                {...props}
                loading={loading}
            />
        </>
    );
};

export default ShippingOrderDetails;
