import React from 'react';
import Wrapper from '../../../helpers/wrapper';
import { StepFourContent } from './StepFour.view';
import { SiteSurveyStepFourForm, StepFourProps } from './StepFour.type';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
// import module

const StepFour: React.FC<StepFourProps> = ({
    activeStep,
    handleNext,
    handleBack,
    handleSubmit
}): JSX.Element => {
    const surveyData  = useRecoilValue(SurveyState);

    const dataStepFour: SiteSurveyStepFourForm = {
        bridgestone: checkSurvey(surveyData, 'bridgestone'),
        mechelin: checkSurvey(surveyData, 'mechelin'),
        goodyear: checkSurvey(surveyData, 'goodyear'),
        otherBranch: checkSurvey(surveyData, 'otherBranch'),
        tyreAdditive: checkSurvey(surveyData, 'tyreAdditive'),
        tyreAdditiveOther: checkSurvey(surveyData, 'tyreAdditiveOther'),
        fullName: checkSurvey(surveyData, 'fullName'),
        jobTitle: checkSurvey(surveyData, 'jobTitle'),
        company: checkSurvey(surveyData, 'company'),
        emailPersonal: checkSurvey(surveyData, 'emailPersonal')
    };

    const optionsSelect = [
        { value: 'no', label: 'No' },
        { value: 'rim_eaze', label: 'Rim Eaze' },
        { value: 'rim_guard', label: 'Rim Guard' },
        { value: 'rimexcel', label: 'Rimexcel' },
        { value: 'water_cart', label: 'Water Cart' },
        { value: 'seal_zint', label: 'Seal-Zint' },
        { value: 'tire_life', label: 'Tire Life' },
        { value: 'other', label: 'Other' }
    ];
    
    return (
        <StepFourContent 
            data-testid='StepFour-testid'
            activeStep={activeStep}
            surveyData={surveyData}
            valuesInitForm={dataStepFour}
            optionsSelect={optionsSelect}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
        />
    );  
};

export default Wrapper(StepFour);