import styled from 'styled-components';

export const GlobalFilterContent = styled.span`

    &&& .MuiTextField-root {
        max-width: 95%;

        @media (max-width: 1915px) {
            max-width: 61%;
        }
    }
`;
