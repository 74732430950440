import React from 'react';
import Wrapper from '../../../helpers/wrapper';
import { StepTwoContent } from './StepTwo.view';
import { OptionsSelectsType, SiteSurveyStepTwoForm, StepTwoProps } from './StepTwo.type';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
import Translator from '../../../states/global/Translator';
// import module

const StepTwo: React.FC<StepTwoProps> = ({
    activeStep,
    handleNext,
    handleBack,
    handleSubmit
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const surveyData  = useRecoilValue(SurveyState);
    
    const dataStepTwo: SiteSurveyStepTwoForm = {
        cellularAvailable: checkSurvey(surveyData, 'cellularAvailable'),
        cellularProvider: checkSurvey(surveyData, 'cellularProvider'),
        cellularRestrictions: checkSurvey(surveyData, 'cellularRestrictions'),
        wifiAvailable: checkSurvey(surveyData, 'wifiAvailable'),
        wifiInternet: checkSurvey(surveyData, 'wifiInternet'),
        wifiFirewall: checkSurvey(surveyData, 'wifiFirewall'),
        wifiRouter: checkSurvey(surveyData, 'wifiRouter'),
        wifiEthernet: checkSurvey(surveyData, 'wifiEthernet'),
        dispatchSystem: checkSurvey(surveyData, 'dispatchSystem'),
        dispatchLink: checkSurvey(surveyData, 'dispatchLink'),
        tmsSystem: checkSurvey(surveyData, 'tmsSystem'),
        networkComment: checkSurvey(surveyData, 'networkComment')
    };

    const optionsSelect: OptionsSelectsType[] = [
        { label: Trans('messages.t.yes'), value: 'yes' },
        { label: Trans('messages.t.no'), value: 'yes' }
    ];

    return (
        <StepTwoContent 
            data-testid='StepTwo-testid'
            activeStep={activeStep}
            surveyData={surveyData}
            valuesInitForm={dataStepTwo}
            optionsSelect={optionsSelect}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(StepTwo);