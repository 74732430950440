import { atom } from 'recoil';
import { SurveyType } from '../../models/ApiSurvey.type';

export const Surveys = atom<SurveyType[]>({
    key: 'surveys-atom',
    default: []
});

export const SurveyState = atom<SurveyType | null>({
    key: 'survey-atom',
    default: null
});

export const SurveyStepState = atom<number>({
    key: 'survey-step-atom',
    default: 1
});

