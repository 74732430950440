import React from 'react';
import { SiteSurveyStepFourForm, StepFourViewProps } from './StepFour.type';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { ListItemCustom, UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { AutocompleteElement, InputElement } from '../../FormElements';
// import module

export const StepFourContent: React.FC<StepFourViewProps> = ({
    valuesInitForm,
    activeStep,
    optionsSelect,
    handleBack,
    handleNext,
    handleSubmit
}): JSX.Element =>  {
    const Trans = useRecoilValue(Translator);

    const validationSchema = Yup.object().shape({
        bridgestone: Yup.number().typeError(Trans('messages.t.must_be_number')),
        mechelin: Yup.number().typeError(Trans('messages.t.must_be_number')),
        goodyear: Yup.number().typeError(Trans('messages.t.must_be_number')),
        otherBranch: Yup.number().typeError(Trans('messages.t.must_be_number')),
        tyreAdditive: Yup.string(),
        tyreAdditiveOther: Yup.string(),
        fullName: Yup.string(),
        jobTitle: Yup.string(),
        company: Yup.string(),
        emailPersonal: Yup.string().email('Invalid email')
    });

    // const validationSchema = Yup.object().shape({
    //     bridgestone: Yup.number()
    //         .typeError(Trans('messages.t.must_be_number'))
    //         .required(Trans('messages.p.this_field_is_required')),
    //     mechelin: Yup.number()
    //         .typeError(Trans('messages.t.must_be_number'))
    //         .required(Trans('messages.p.this_field_is_required')),
    //     goodyear: Yup.number()
    //         .typeError(Trans('messages.t.must_be_number'))
    //         .required(Trans('messages.p.this_field_is_required')),
    //     otherBranch: Yup.number()
    //         .typeError(Trans('messages.t.must_be_number'))
    //         .required(Trans('messages.p.this_field_is_required')),
    //     tyreAdditive: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     tyreAdditiveOther: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     fullName: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     jobTitle: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     company: Yup.string().required(Trans('messages.p.this_field_is_required')),
    //     emailPersonal: Yup.string().required(Trans('messages.p.this_field_is_required')).email('Invalid email')
    // });
    
    return  (
        <Formik
            initialValues={valuesInitForm}
            onSubmit={(values: SiteSurveyStepFourForm, actions) => {
                handleSubmit(values, 4, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
        >
            {(propsF: FormikProps<SiteSurveyStepFourForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm
                    // resetForm
                } = propsF;

                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            <Grid container spacing={2}>
                                <Grid item md={5} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{Trans('messages.t.tyre_information')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container>
                                        <Grid item sm={12} md={7} sx={{ paddingTop: '20px' }} >
                                            <strong>Manufacturer Split (%)</strong>
                                            {/* <strong>{Trans('messages.p.manufacturer_split')} (%)</strong> */}
                                        </Grid>
                                        <Grid item sm={12} md={5}>
                                            <InputElement
                                                label='Bridgestone'
                                                name='bridgestone'
                                                value={values.bridgestone}
                                                error={errors.bridgestone}
                                                touched={touched.bridgestone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                            <InputElement
                                                label='Mechelin'
                                                name='mechelin'
                                                value={values.mechelin}
                                                error={errors.mechelin}
                                                touched={touched.mechelin}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                            <InputElement
                                                label='Goodyear'
                                                name='goodyear'
                                                value={values.goodyear}
                                                error={errors.goodyear}
                                                touched={touched.goodyear}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                             <InputElement
                                                label='Other'
                                                name='otherBranch'
                                                value={values.otherBranch}
                                                error={errors.otherBranch}
                                                touched={touched.otherBranch}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={7}><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item sm={12} md={7} sx={{ paddingTop: '20px' }} >
                                            <strong>Is tyre additive used?</strong>
                                            {/* <strong>{Trans('messages.p.tyre_additive_used')}</strong> */}
                                        </Grid>
                                        <Grid item sm={12} md={5}>
                                           <AutocompleteElement
                                                label=''
                                                name='tyreAdditive'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.tyreAdditive}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.tyreAdditive
                                                )}
                                                error={errors.tyreAdditive}
                                                touched={touched.tyreAdditive}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                            <InputElement
                                                label={Trans('messages.t.other')}
                                                name='tyreAdditiveOther'
                                                value={values.tyreAdditiveOther}
                                                error={errors.tyreAdditiveOther}
                                                touched={touched.tyreAdditiveOther}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={values.tyreAdditive !== 'other'}
                                                required={false}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={7}><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={7} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{Trans('messages.t.personnel_information')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container>
                                        <InputElement
                                            label={Trans('messages.t.full_name')}
                                            name='fullName'
                                            value={values.fullName}
                                            error={errors.fullName}
                                            touched={touched.fullName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                        <InputElement
                                            label={Trans('messages.t.job_title')}
                                            name='jobTitle'
                                            value={values.jobTitle}
                                            error={errors.jobTitle}
                                            touched={touched.jobTitle}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                        <InputElement
                                            label={Trans('messages.t.company')}
                                            name='company'
                                            value={values.company}
                                            error={errors.company}
                                            touched={touched.company}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                        <InputElement
                                            label={Trans('messages.t.email')}
                                            name='emailPersonal'
                                            value={values.emailPersonal}
                                            error={errors.emailPersonal}
                                            touched={touched.emailPersonal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </UiScrollbarsSiteSurvey>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pb: 2 }}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} variant='contained' size='small' sx={{ mr: 1 }}>
                                    Back
                                </Button>
                            )}
                            {activeStep < 4 && (
                                <Button 
                                    onClick={handleNext} 
                                    sx={{ mr: 1 }} 
                                    variant='contained'
                                    size='small'
                                    disabled={
                                        Object.keys(touched).length === 0 || 
                                        Object.keys(touched).length > 0 && Object.keys(errors).length > 0
                                    }
                                >
                                    Next
                                </Button>
                            )}
                            <Button 
                                onClick={() => {
                                    submitForm();
                                }} 
                                sx={{ mr: 1 }} 
                                variant='contained'
                                size='small'
                                disabled={isSubmitting}
                            >
                                {activeStep === 4 ? 'Finish' : 'Save'} 
                            </Button>
                        </Box>
                    </>
                );
            }}
        </Formik>
    );
};