import React from 'react';
import { SiteSurveyStepThreeForm, StepThreeViewProps } from './StepThree.type';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Box, Button, Grid } from '@mui/material';
import { AutocompleteElement, InputElement } from '../../FormElements';
import { FleetInformationTable } from '../FleetInformation.table';
import { IUAlert } from '../../../styled/global.style';
// import module

export const StepThreeContent: React.FC<StepThreeViewProps> = ({
    valuesInitForm,
    activeStep,
    optionsSelect,
    items,
    showMsg,
    handleBack,
    handleNext,
    handleSubmit,
    handleAdd,
    handleRemoveItems
}): JSX.Element =>  {
    const Trans = useRecoilValue(Translator);

    const validationSchema = Yup.object().shape({
        totalQuantity: Yup.string().required(Trans('messages.p.this_field_is_required'))
    });
    
    return  (
        <Formik
            initialValues={valuesInitForm}
            onSubmit={(values: SiteSurveyStepThreeForm, actions) => {
                handleSubmit({
                    vehicles: items,
                    totalQuantity: values.totalQuantity
                }, 3, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
        >
            {(propsF: FormikProps<SiteSurveyStepThreeForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm,
                    resetForm
                } = propsF;

                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            {showMsg && (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            )}
                            <Grid container justifyContent='inicial' direction='row' spacing={2} sx={{ mb: 2, mt: 2 }}>
                                <InputElement
                                    label={Trans('messages.t.model')}
                                    name='model'
                                    value={values.model}
                                    error={errors.model}
                                    touched={touched.model}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />
                                
                                <AutocompleteElement
                                    label={Trans('messages.t.type')}
                                    name='type'
                                    size='small'
                                    options={optionsSelect ?? []}
                                    value={values.type}
                                    defaultValue={optionsSelect.find(
                                        option => option.value === values.type
                                    )}
                                    error={errors.type}
                                    touched={touched.type}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    optionsPropertyLabel='label'
                                    optionsPropertyValue='value'
                                    required={true}
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={Trans('messages.t.quantity')}
                                    name='quantity'
                                    value={values.quantity}
                                    error={errors.quantity}
                                    touched={touched.quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={Trans('messages.t.utilisation')}
                                    name='utilisation'
                                    value={values.utilisation}
                                    error={errors.utilisation}
                                    touched={touched.utilisation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={Trans('messages.t.max_speed')}
                                    name='maxSpeed'
                                    value={values.maxSpeed}
                                    error={errors.maxSpeed}
                                    touched={touched.maxSpeed}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={Trans('messages.t.tyre_size')}
                                    name='tyreSize'
                                    value={values.tyreSize}
                                    error={errors.tyreSize}
                                    touched={touched.tyreSize}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={Trans('messages.t.front_life')}
                                    name='frontLife'
                                    value={values.frontLife}
                                    error={errors.frontLife}
                                    touched={touched.frontLife}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={Trans('messages.t.rear_life')}
                                    name='frontLife'
                                    value={values.rearLife}
                                    error={errors.rearLife}
                                    touched={touched.rearLife}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <Grid item md={1} sx={{ mt: 1.3, ml: 1.5 }}>
                                    <Button variant='contained'
                                        onClick={() => {
                                            handleAdd(values, resetForm);
                                        }}
                                    >
                                        {Trans('messages.t.add')}
                                    </Button>
                                </Grid>

                                <Grid item md={12} sx={{ mt: 2, borderBottom: 'thin solid #c0c0c0' }}>
                                    <FleetInformationTable items={items} handleRemoveItems={handleRemoveItems} />
                                </Grid>

                                <InputElement
                                    label={Trans('messages.t.total_quantity')}
                                    name='totalQuantity'
                                    value={values.totalQuantity}
                                    error={errors.totalQuantity}
                                    touched={touched.totalQuantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    fullWidth
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                />
                            </Grid> 
                        </UiScrollbarsSiteSurvey>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pb: 2 }}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} variant='contained' size='small' sx={{ mr: 1 }}>
                                    Back
                                </Button>
                            )}
                            {activeStep < 4 && (
                                <Button 
                                    onClick={handleNext} 
                                    sx={{ mr: 1 }} 
                                    variant='contained'
                                    size='small'
                                    disabled={
                                        Object.keys(touched).length === 0 || 
                                        Object.keys(touched).length > 0 && Object.keys(errors).length > 0 ||
                                        items.length === 0
                                    }
                                >
                                    Next
                                </Button>
                            )}
                            <Button 
                                onClick={() => {
                                    submitForm();
                                }} 
                                sx={{ mr: 1 }} 
                                variant='contained'
                                size='small'
                                disabled={items.length === 0 || isSubmitting}
                            >
                                {activeStep === 4 ? 'Finish' : 'Save'} 
                            </Button>
                        </Box>
                    </>
                );
            }}
        </Formik>
    );
};