import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue } from 'recoil';
import * as type from './BoxItems.type';
import * as view from './BoxItems.view';
import ApiBox from '../../api/ApiBox';
import * as BoxModel from '../../models/ApiBox.type';
import ApiItems from '../../api/ApiItems';
import Wrapper from '../../helpers/wrapper';
import Translator from '../../states/global/Translator';
import { Success } from '../Popup/Popup';
import { ColumnsTable } from '../../types/common';
import IconButton from '@mui/material/IconButton';
import { Add, Delete, Remove } from '@mui/icons-material';
import { TextEllipsis } from '../../styled/global.style';
import { ModelItemsLocation, StockItemsType } from '../../models/ApiItems.type';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { toCamelCaseAndRemove_ } from '../../helpers/converter';
import { Box, Fab } from '@mui/material';
import { NO_DATA } from '../../helpers/variables';
// import module

const apiBox = new ApiBox();
const apiItems = new ApiItems();

const BoxItems: React.FC<type.BoxItemsProps> = (props) => {
    const [modalItemsLoading, setModalItemsLoading] = useState<boolean>(true);
    const [dataBoxItems, setdataBoxItems] = useState<StockItemsType[]>([]);
    const [boxData, setboxData] = useState<StockItemsType | null>(null);
    const [columnsBoxItems, setColumnsBoxItems] = useState<ColumnsTable[]>([]);
    const Trans = useRecoilValue(Translator);
    const [itemsPartialBox, setItemsPartialBox] = useState<StockItemsType[]>([]);

    const updateReshipped = (
        reshippedItems: { invoiceNumber: string; childrenId: number }[],
        childrens: StockItemsType[]
    ): StockItemsType[] => {
        return childrens.map((child) => {
            if (child.id) {
                const facturaCoincidente = reshippedItems.find(
                    (items) => items.childrenId === child.id
                );

                if (facturaCoincidente) {
                    return {
                        ...child,
                        reshipped: facturaCoincidente.invoiceNumber
                    };
                }
            }
            return child;
        });
    };

    const getItems = async (id): Promise<void> => {
        props.setBoxId(id);
        setModalItemsLoading(true);

        try {
            if (props.hash) {
                const {
                    data: { item }
                }: BoxModel.ModelGeneral = await apiBox.getGeneral(`stock/public/item/${id}/${props.hash}`);

                if (!item) {
                    throw item;
                }

                setdataBoxItems(item?.children);
                setboxData(item);
            } else {
                const data: ModelItemsLocation = await apiItems.getItemsById(id);

                if (!data.item) {
                    throw data.item;
                }

                let getData = data.item.children;

                if (getData && getData.length) {
                    const children = getData.map(child => ({ ...child, added: false }));
                    getData = children;
                }

                if (props.hiddenItems && props.hiddenItems.length) {
                    const newList = props?.hiddenItems ?
                        getData.filter(child => !(props?.hiddenItems as number[]).includes(child.id))
                        : getData;

                    getData = newList;
                }

                if (props.reshippedItems && props.reshippedItems.length && getData.length) {
                    getData = updateReshipped(props.reshippedItems, getData);
                }

                setdataBoxItems(getData);
                setboxData(data.item);
            }
        } catch (error) {
            console.error('Error to sending data ', error);
        }
        setModalItemsLoading(false);
    };

    const confirmDeleteItemsBox = (row, boxData) => {
        row.boxData = boxData;
        return popUpConfirm(
            Trans('messages.p.remove_items_from_the_box') + ` #${row.id}`,
            Trans('messages.p.are_you_sure_to_delete'),
            removeItemsBox,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );
    };

    const removeItemsBox = async (onClose, { id, sku, boxData }): Promise<void> => {
        const newListItems: StockItemsType[] = boxData.children?.filter((i) => i.id !== id);
        setdataBoxItems(newListItems);

        const urlBaseItemsBox: string = props.hash
            ? `public/stock/item/box/${props.BoxId}/${props.hash}`
            : `stock/item/box/${props.BoxId}`;

        const newDataToSend: type.stockItemSend = {
            items: newListItems.map((i) => ({ id: i.id, quantity: i.quantity })),
            sku: boxData.sku.id,
            location: boxData.location.id,
            attributes: boxData.itemAttribute.map((i) => ({ box_label: i.value }))
        };

        try {
            await apiItems.patchGeneralFree(urlBaseItemsBox, newDataToSend);
            const skuId: string = Object.keys(sku).length ? sku.id + ' ' : '';
            Success({
                text: Trans('messages.t.item') + ': ' + skuId + Trans('messages.p.was_removed_from_the_box')
            });
            onClose();
        } catch (error) {
            console.error('Error to send data ', error);
        }
    };

    const resetSelectedItems = (): void => {
        setItemsPartialBox([]);
        setdataBoxItems(prevState => {
            return prevState.map(boxItem => {
                return { ...boxItem, added: false };
            });
        });
    };

    const addItem = useCallback(
        (item: StockItemsType): void => {
            setItemsPartialBox((prevState) => {
                if (item.added) {
                    return [
                        ...prevState,
                        item
                    ];
                } else {
                    return prevState.filter(itemState => itemState.id !== item.id);
                }
            });

            setdataBoxItems(prevState => {
                return prevState.map(boxItem => {
                    if (boxItem.id === item.id) {
                        return item;
                    } else {
                        return boxItem;
                    }
                });
            });
        },
        [dataBoxItems]
    );

    const getColumns = async (): Promise<void> => {
        const columnsBoxItem: ColumnsTable[] = [
            {
                Header: Trans('messages.t.serial_number'),
                accessor: 'serialNumber',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 120,
                Cell: ({ row: { original } }: CellProps) =>
                    original.sku.skuType.type && original.sku.skuType.type !== 'box'
                        ? original.serialNumber
                        : original?.label
                            ? original.label
                            : NO_DATA
            }
        ];

        if (props.public) {
            columnsBoxItem.push({
                Header: Trans('messages.t.name'),
                accessor: 'sku.customerLabel',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 100
            });
        } else {
            // push ID column to start columnsBoxItem array
            columnsBoxItem.unshift({
                Header: 'ID',
                accessor: 'id',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 50
            });

            columnsBoxItem.push({
                Header: Trans('messages.t.sku_code'),
                accessor: 'sku.skuCode',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 100,
                Cell: ({ row: { original } }: CellProps) =>
                    original.sku.skuType.type && original.sku.skuType.type === 'box' ? NO_DATA : original.sku.skuCode
            });

            columnsBoxItem.push({
                Header: Trans('messages.t.sku_code'),
                accessor: 'skuCustomerCode',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                width: 0,
                Export: ({ row }: CellProps) =>
                    row.original.sku.skuType.type && row.original.sku.skuType.type === 'box'
                        ? NO_DATA :
                        row.original.sku.skuCodeCustomer,
                Cell: ({ row }: CellProps) =>
                    row.original.sku.skuType.type && row.original.sku.skuType.type === 'box'
                        ? NO_DATA :
                        row.original.sku.skuCodeCustomer
            }),

                columnsBoxItem.push({
                    Header: Trans('messages.t.name'),
                    accessor: 'sku.name',
                    disableGroupBy: true,
                    aggregate: 'count',
                    canFilters: true,
                    Export: ({ row }: CellProps) => row.original.sku.name,
                    Cell: ({ row }: CellProps) => (
                        <TextEllipsis title={row.original.sku.name}> {row.original.sku.name} </TextEllipsis>
                    )
                });

            columnsBoxItem.push({
                Header: Trans('messages.t.name'),
                accessor: 'skuCustomerName',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                width: 0,
                Export: ({ row }: CellProps) => row.original.sku.customerLabel,
                Cell: ({ row }: CellProps) => row.original.sku.customerLabel
            });
        }

        columnsBoxItem.push({
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        });

        columnsBoxItem.push({
            Header: Trans('messages.t.type'),
            accessor: 'sku.skuType.type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50,
            Cell: ({ row: { original } }: CellProps) => {
                if (original.sku?.skuType && !!Object.keys(original.sku.skuType).length) {
                    let name = toCamelCaseAndRemove_(original.sku.skuType.type);

                    if (original.sku.skuType.type === 'hub') {
                        name =
                            toCamelCaseAndRemove_(original.sku.name) +
                            ' [ID:' +
                            (original.itrackDeviceId || original.itemId) +
                            ']';
                    }

                    return name;
                }

                return '';
            }
        });

        if (!props.public) {
            columnsBoxItem.push({
                Header: Trans('messages.t.shipping_price_in_usd'),
                accessor: 'shippingOrderItemPriceUsd',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                Cell: ({ row: { original } }: CellProps) => original.shippingOrderItemPriceUsd ?? NO_DATA
            });

            columnsBoxItem.push({
                Header: Trans('messages.t.reshipped'),
                accessor: 'reshipped',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                Cell: ({ row: { original } }: CellProps) => original.reshipped ?? NO_DATA
            });
        }

        columnsBoxItem.push({
            Header: Trans('messages.t.action'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            width: 50,
            Cell: ({ row: { original } }: CellProps) => (
                <Box sx={{ display: 'inline-flex' }}>
                    {props.sendSelectedItems ? (
                        <Fab
                            sx={{ width: '25px', height: '25px', minHeight: '25px', mt: 0.5 }}
                            size='small'
                            color={original.added ? 'error' : 'success'}
                            title={Trans(original.added ? 'messages.t.remove' : 'messages.t.add')}
                            id={`addItems${original.id}`}
                            onClick={() => addItem({ ...original, added: !original.added })}
                            data-testid='BoxItem-buttonAdd'
                        >
                            {original.added ? <Remove /> : <Add />}
                        </Fab>
                    ) : (<></>)}
                    <IconButton
                        disabled={!props.showAction}
                        size='small'
                        className='danger'
                        title={Trans('messages.t.remove')}
                        id={`removeItems${original.id}`}
                        onClick={() => confirmDeleteItemsBox(original, boxData)}
                        data-testid='BoxItem-buttonDelete'
                    >
                        <Delete />
                    </IconButton>
                </Box>
            )
        });

        props.hash && columnsBoxItem.splice(5);
        setColumnsBoxItems(columnsBoxItem);
    };

    const closeModalItems = () => {
        setdataBoxItems([]);
        setboxData(null);
        props.setModalItems(false);
        props.setBoxId(null);
    };

    useEffect(() => {
        getColumns();
    }, [boxData]);

    useEffect(() => {
        if(itemsPartialBox && itemsPartialBox.length) {
            itemsPartialBox.map((itemP) => {

                setdataBoxItems(prevState => {
                    return prevState.map(boxItem => {
                        if (boxItem.id === itemP.id) {
                            return itemP;
                        } else {
                            return boxItem;
                        }
                    });
                });
                return itemP;
            });
        }
        
    }, [modalItemsLoading]);

    useEffect(() => {
        props.BoxId && getItems(props.BoxId);
    }, [props.BoxId]);

    return (
        <view.BoxItemsContent
            data-testid='BoxItems-testid'
            columnsBoxItems={columnsBoxItems}
            modalItems={props.modalItems}
            modalItemsLoading={modalItemsLoading}
            dataBoxItems={dataBoxItems}
            closeModalItems={closeModalItems}
            typeModal={props.typeModal ? props.typeModal : 'modal'}
            boxId={props.BoxId}
            itemsPartialBox={itemsPartialBox}
            sendSelectedItems={props.sendSelectedItems}
            resetSelectedItems={resetSelectedItems}
        />
    );
};

export default Wrapper(BoxItems);
