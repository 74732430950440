import React, { useState } from 'react';
import Wrapper from '../../../helpers/wrapper';
import { StepThreeContent } from './StepThree.view';
import { SiteSurveyStepThreeForm, StepThreeProps } from './StepThree.type';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
// import module

const VALUESINITFROM = {
    model: '',
    type: '',
    quantity: '',
    utilisation: '',
    maxSpeed: '',
    tyreSize: '',
    frontLife: '',
    rearLife: '',
    totalQuantity: ''
};

const StepThree: React.FC<StepThreeProps> = ({
    activeStep,
    handleNext,
    handleBack,
    handleSubmit
}): JSX.Element => {
    const surveyData  = useRecoilValue(SurveyState);
    const [items, setItems] = useState<any[]>(surveyData?.customerOnboarding?.data.vehicles ?? []);
    const [showMsg, setShowMsg] = useState<boolean>(false);
    const [valuesInitForm, setValuesInitForm] = useState<SiteSurveyStepThreeForm>({
        ...VALUESINITFROM,
        totalQuantity: checkSurvey(surveyData, 'totalQuantity')
    });

    const optionsSelect = [
        { value: 'haul_truck', label: 'Haul Truck' },
        { value: 'front_end_loader', label: 'Front End Loader' },
        { value: 'water_cart', label: 'Water Cart' },
        { value: 'wheel_dozer', label: 'Wheel Dozer' },
        { value: 'grader', label: 'Grader' },
        { value: 'articulated_dump_truck', label: 'Articulated Dump Truck' },
        { value: 'other_state_other', label: 'Other (state other)' }
    ];

    const handleAdd = (values, reset): void => {
        if (values?.model && values?.type && values?.quantity) {
            setItems([...items, values]);
            reset({});
            setValuesInitForm(VALUESINITFROM);
            setShowMsg(false);
        }else{
            setShowMsg(true);
        }
    };

    const handleRemoveItems = (item): void => {
        if (items && items.length) {
            setItems(items.filter((i) => i.type !== item.type));
        }
    };

    return (
        <StepThreeContent
            data-testid='StepThree-testid'
            activeStep={activeStep}
            surveyData={surveyData}
            valuesInitForm={valuesInitForm}
            optionsSelect={optionsSelect}
            items={items}
            showMsg={showMsg}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            handleAdd={handleAdd}
            handleRemoveItems={handleRemoveItems}
        />
    );
};

export default Wrapper(StepThree);