import React, { useEffect, useState } from 'react';
import Wrapper from '../../helpers/wrapper';
import SiteSurveyContent from './SiteSurvey.view';
import { SiteSurveyProps } from './SiteSurvey.type';
import { ItrackConfigLogFormInitValues } from '../ItrackConfigLog/ItrackConfigLog.type';
import ApiSurvey from '../../api/ApiSurvey';
import { SurveyResponseType } from '../../models/ApiSurvey.type';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SurveyState, SurveyStepState } from '../../states/component/Survey';
import { Success } from '../Popup/Popup';
import Translator from '../../states/global/Translator';
// import module

const apiSurvey = new ApiSurvey();

const cleanObject = <T extends Record<string, any>>(object: T): Partial<T> => {
    return Object.keys(object).reduce((newObject, property) => {
      const value = object[property];
      if (value !== null && value !== undefined && value !== "") {
        newObject[property as keyof T] = value;
      }
      return newObject;
    }, {} as Partial<T>);
};

const valuesInitFormLast: ItrackConfigLogFormInitValues = {
    mineName: '',
    startTrialOrContractDate: '',
    location: '',
    language: '',
    languageOther: '',
    pressureUnitLevel1: '',
    pressureUnitLevel2: '',
    pressureUnitLevel3: '',
    temperatureUnitLevel1: '',
    temperatureUnitLevel2: '',
    temperatureUnitLevel3: '',
    recommededColdPressure: '',
    lowPressureLimit: '',
    highPressureLimit: '',
    emperatureLimits: '',
    maxSpeedLimit: '',
    speedUnitLevel1: '',
    speedUnitLevel2: '',
    speedUnitLevel3: '',
    shiftChangeStartDay: '',
    shiftStartTime: '',
    shiftEndTime: '',
    lunchtimeDayStart: '',
    lunchtimeDayEnd: '',
    lunchtimeNightStart: '',
    lunchtimeNightEnd: '',
    shiftName: '',
    shiftStart: '',
    shiftEnd: '',
    siteName: '',
    region: '',
    closestCity: '',
    timeRegion: '',
    dateOfIntalation: '',
    primaryDeliveryLocation: ''
};

const SiteSurvey: React.FC<SiteSurveyProps> = ({ token, id }): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const [survey, setSurvey]  = useRecoilState(SurveyState);
    const setSurveyStep = useSetRecoilState(SurveyStepState);
    const [loadingData, setLoadingData] = useState<boolean>(true);

    const getSurvey = async () => {
        const param = token ?? id;
        const { customerOnboardingInvitation }: SurveyResponseType = await apiSurvey.getByTokenOrId(param ?? 0);

        if( token ) {
            await apiSurvey.emailSeen(customerOnboardingInvitation.token);
        }

        setSurvey(customerOnboardingInvitation);
        // DESCOMENTAR
        setSurveyStep(customerOnboardingInvitation.customerOnboarding?.step 
            ? (customerOnboardingInvitation.customerOnboarding?.step - 1) 
            : 1
        );
        // setSurveyStep(3);
        setLoadingData(false);
    };

    const handleSubmit = async (
        e,
        step: number,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting
    ): Promise<void> => {
        try {
            const idOrToken = token ?? id;
            const dataSend = { ...survey?.customerOnboarding?.data, ...cleanObject(e) };

            const {
                data: { customerOnboarding }
            }: any = await apiSurvey.saveStep(idOrToken ?? 0, {
                step: step, 
                data: JSON.stringify(dataSend)
            });

            if (!customerOnboarding) {
                throw customerOnboarding;
            }

            Success({
                text: `${Trans('messages.t.saved_changes')}`
            });

            setSurvey(prevSurvey => ({ ...prevSurvey, ...customerOnboarding.data }));
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };
    
    useEffect(() => {
        getSurvey();
    }, []);

    return (
        <SiteSurveyContent 
            valuesInitFormLast={valuesInitFormLast}
            loadingData={loadingData}
            handleSubmit={handleSubmit}
            data-testid='SiteSurvey-testid' 
        />
    );
};

export default Wrapper(SiteSurvey);