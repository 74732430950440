import React, { useEffect, useState } from 'react';
import { CreateInvitacionProps, CreateInvitationForm } from './CreateInvitation.type';
import * as view from './CreateInvitation.view';
import Wrapper from '../../../helpers/wrapper';
import { Success } from '../../Popup/Popup';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import { TypeRegions } from '../../../models/ApiRegions.type';
import { ModelCustomerItrack } from '../../../models/ApiCustomer.type';
import ApiRegions from '../../../api/ApiRegions';
import ApiCustomer from '../../../api/ApiCustomer';
import ApiTimeZone from '../../../api/ApiTimeZone';
import ApiSurvey from '../../../api/ApiSurvey';
import { OrderAlphabetically } from '../../../helpers/converter';
import { TimeZoneType } from '../../../models/ApiTimeZone.type';
// import module

const apiRegions = new ApiRegions();
const apiCustomer = new ApiCustomer();
const apiTimeZone = new ApiTimeZone();
const apiSurvey = new ApiSurvey();

const CreateInvitation:React.FC<CreateInvitacionProps> = ({ closeModal, setRefresh }): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const [regions, setRegions] = useState<TypeRegions[]>([]);
    const [customers, setCustomers] = useState<ModelCustomerItrack[]>([]);
    const [timeZones, setTimeZones] = useState<TimeZoneType[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(true);

    const valuesInitForm: CreateInvitationForm = {
        email: '',
        name: '',
        customer: undefined,
        region: undefined,
        timezone: undefined
    };

    const handleSubmit = async (
        e: CreateInvitationForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting
    ): Promise<void> => {
        try {
            if(!e.customer) {
                delete e.customer;
            }

            if(!e.region) {
                delete e.region;
            }

            if(!e.timezone) {
                delete e.timezone;
            }

            const {
                data: { customerOnboardingInvitation }
            }: any = await apiSurvey.create(e);

            if (!customerOnboardingInvitation) {
                throw customerOnboardingInvitation;
            }

            resetForm({});

            Success({
                text: `${Trans('messages.t.survey._created_successfully')}`
            });

            setRefresh(true);
            setSubmitting(false);
            closeModal();
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const getSelect = async () => {
        const { items }: TypeRegions = await apiRegions.get('?limit=200');
        const customers: ModelCustomerItrack[] = await apiCustomer.getCustomersCodeBook();
        const timeZones: TimeZoneType[] = await apiTimeZone.getTimeZoneCodeBook();

        items && setRegions(OrderAlphabetically(items, 'translationString'));
        customers && setCustomers(OrderAlphabetically(customers, 'name'));
        timeZones && setTimeZones(OrderAlphabetically(timeZones, 'timezoneName'));
        setLoadingData(false);
    };

    useEffect(() => {
        getSelect();
    }, []);

    return (
        <view.CreateInvitationContent 
            data-testid='CreateInvitacion-testid'
            valuesInitForm={valuesInitForm}
            regions={regions}
            customers={customers}
            timeZones={timeZones}
            loadingData={loadingData}
            handleSubmit={handleSubmit}
        />
    );  
};

export default Wrapper(CreateInvitation);