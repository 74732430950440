import { List } from '@mui/material';
import styled from 'styled-components';

export const EmailsListContent = styled.div``;

export const IconButtonContent = styled.div`
    width: 45px;
`;

export const ListContainer = styled(List)`
    max-height: 210px;
    min-width: 200px;
    overflow-y: auto;
`;