import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    TextField,
    Tooltip
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { CloudUpload } from '@mui/icons-material';
import React, { useState } from 'react';
import {
    AutocompleteElementType,
    CheckboxElementType,
    InputElementType,
    InputFileElementType,
    LoadingElementType
} from './FormElements.type';
import { AsyncPaginate } from 'react-select-async-paginate';
import { CustomStylesSelectAsyncPaginate, GridFieldContainer } from '../../styled/global.style';
import { GetOptionLabel } from '../../types/common';
import { toCamelCaseAndRemove_ } from '../../helpers/converter';
import Translator from '../../states/global/Translator';
import { useRecoilValue } from 'recoil';

/* eslint-disable */
export const ChipsAutocompleteElement = ({
    label,
    name,
    value,
    defaultValue = [],
    error,
    autoFocus = false,
    optionsPropertyValue = 'value',
    onBlur,
    disabled = false,
    xl = 6,
    lg = 6,
    md = 6,
    sm = 6,
    setFieldValue,
    onChange,
    show = true,
    filterSelectedOptions = false,
    required = false,
    size = 'small'
}: AutocompleteElementType) => {
    const Trans = useRecoilValue(Translator);
    return (
    <GridFieldContainer item xl={xl} lg={lg} md={md} sm={sm} xs={12} visibility={show ? 'visible' : 'hidden'}>
        <Tooltip title={Trans('messages.t.press_enter_to_apply')}>
            <Autocomplete
                multiple={true}
                size={size}
                filterSelectedOptions={filterSelectedOptions}
                id={name}
                style={{ minWidth: 200 }}
                freeSolo
                options={[]}
                autoComplete={false}
                value={value}
                defaultValue={defaultValue}
                onChange={
                    onChange
                        ? onChange
                        : (e, value: any) => {
                            setFieldValue &&
                                setFieldValue(
                                    name,
                                    value.map((item) => {
                                        return item[optionsPropertyValue];
                                    })
                                );
                        }
                }
                onBlur={onBlur}
                renderTags={(value: any[], getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes) =>
                    value.map((option: any, index: any) => {
                        return <Chip key={index} variant='outlined' label={option} {...getTagProps({ index })} />;
                    })
                }
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        autoFocus={autoFocus}
                        value={value}
                        autoComplete='off'
                        name={name}
                        {...params}
                        label={
                            required ? (
                                <span className='fieldRequired'>
                                    <i>*</i> {label}
                                </span>
                            ) : (
                                label
                            )
                        }
                        variant='outlined'
                        helperText={error ? error : ''}
                        error={error ? true : false}
                    />
                )}
            />
        </Tooltip>
    </GridFieldContainer>
)};

/* eslint-disable */
export const InputElement = ({
    label,
    name,
    value,
    error,
    touched,
    onChange,
    autoFocus = false,
    onBlur,
    multiline = false,
    disabled = false,
    type = 'text',
    xl = 6,
    lg = 6,
    md = 6,
    sm = 6,
    xs = 12,
    rows = 2,
    show = true,
    required = false,
    size = 'small',
    fullWidth = false,
    placeholder
}: InputElementType) => (
    <GridFieldContainer item xl={xl} lg={lg} md={md} sm={sm} xs={xs} show={show ? 'grid' : 'none'}>
        <TextField
            name={name}
            autoFocus={autoFocus}
            id={name}
            fullWidth={fullWidth}
            size={size}
            label={
                required ? (
                    <span className='fieldRequired'>
                        <i>*</i> {label}
                    </span>
                ) : (
                    label
                )
            }
            value={value}
            type={type}
            helperText={error ? error : ''}
            error={Boolean(error)}
            onChange={onChange}
            onBlur={onBlur}
            variant='outlined'
            disabled={disabled}
            multiline={multiline}
            rows={rows}
            placeholder={placeholder}
        />
    </GridFieldContainer>
);

export const OutlinedInputElement = ({
    label,
    name,
    value,
    error,
    endAdornment,
    onChange,
    disabled = false,
    xl = 6,
    lg = 6,
    md = 6,
    sm = 6,
    xs = 12,
    show = true,
    required = false,
    size = 'small'
}: InputElementType) => (
    <GridFieldContainer item xl={xl} lg={lg} md={md} sm={sm} xs={xs} show={show ? 'grid' : 'none'}>
        <FormControl sx={{ width: '-webkit-fill-available' }} variant='outlined'>
            <InputLabel className='outlinedInputLabel' htmlFor='adornment-filter'>
                {required ? (
                    <span className='fieldRequired'>
                        <i>*</i> {label}
                    </span>
                ) : (
                    label
                )}
            </InputLabel>
            <OutlinedInput
                value={value}
                name={name}
                id={name}
                onChange={onChange}
                endAdornment={endAdornment}
                disabled={disabled}
                label={label}
                size={size}
                error={Boolean(error)}
            />
            {Boolean(error) && (
                <FormHelperText error id='filter-error'>
                    {error}
                </FormHelperText>
            )}
        </FormControl>
    </GridFieldContainer>
);

export const AutocompleteElement = ({
    label,
    name,
    value,
    error,
    touched,
    autoFocus = false,
    optionsPropertyLabel = 'label',
    optionsPropertyLabel2 = null,
    optionsPropertyValue = 'value',
    onBlur,
    disabled = false,
    xl = 6,
    lg = 6,
    md = 6,
    sm = 6,
    setFieldValue,
    options,
    defaultValue,
    multiple = false,
    onChange,
    show = true,
    filterSelectedOptions = false,
    required = false,
    size = 'small',
    getOptionLabel
}: AutocompleteElementType) => (
    <GridFieldContainer item lg={lg} md={md} sm={sm} xs={12} xl={xl} visibility={show ? 'visible' : 'hidden'}>
        <Autocomplete
            multiple={multiple}
            size={size}
            filterSelectedOptions={filterSelectedOptions}
            id={name}
            style={{ minWidth: 200 }}
            options={options}
            onChange={
                onChange
                    ? onChange
                    : (e, value: any) => {
                          multiple
                              ? setFieldValue &&
                                setFieldValue(
                                    name,
                                    value.map((item) => {
                                        return item[optionsPropertyValue];
                                    })
                                )
                              : setFieldValue && setFieldValue(name, value ? value[optionsPropertyValue] : '');
                      }
            }
            onBlur={onBlur}
            getOptionLabel={
                getOptionLabel
                    ? getOptionLabel
                    : (option: GetOptionLabel): string => {
                          let nameOption: string = toCamelCaseAndRemove_(option[optionsPropertyLabel]);

                          if (optionsPropertyLabel2 && option[optionsPropertyLabel2]) {
                              nameOption = toCamelCaseAndRemove_(option[optionsPropertyLabel2]);
                              if (option[optionsPropertyLabel]) {
                                  nameOption =
                                      toCamelCaseAndRemove_(option[optionsPropertyLabel]) +
                                      ' - ' +
                                      toCamelCaseAndRemove_(option[optionsPropertyLabel2]);
                              }
                          }
                          return option ? nameOption : '';
                      }
            }
            defaultValue={multiple && defaultValue[0] === '' ? [] : defaultValue}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    autoFocus={autoFocus}
                    value={value}
                    name={name}
                    {...params}
                    label={
                        required ? (
                            <span className='fieldRequired'>
                                <i>*</i> {label}
                            </span>
                        ) : (
                            label
                        )
                    }
                    variant='outlined'
                    helperText={error ? error : ''}
                    error={error ? true : false}
                />
            )}
        />
    </GridFieldContainer>
);

export const InputFileElement = ({
    label,
    name,
    accept = '.csv,.xls,.xlsx',
    error,
    titleDocument,
    titleDownloadBtn,
    titleBtn,
    touched,
    titleText,
    setFieldValue,
    handleFileChange,
    disabled = false,
    initImage = '',
    initUrl = '',
    showPreview = true,
    lg = 6,
    md = 6,
    sm = 6,
    maxSizeKb = 6000
}: InputFileElementType) => {
    const [selectedImage, setSelectedImage] = useState<any>();
    const [validationError, setValidationError] = useState<any>();

    return (
        <GridFieldContainer item lg={lg} md={md} sm={sm} xs={12}>
            <input
                accept={accept}
                id={name}
                name={name}
                type='file'
                style={{ display: 'none' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const maxSizeByte = maxSizeKb * 1000;
                    setValidationError('');

                    if (event.currentTarget.files) {
                        const file = event.currentTarget.files[0];

                        if (file.size > maxSizeByte) {
                            setValidationError(
                                `The file size must be less than ${Math.round(
                                    maxSizeKb / 1000
                                )}MB. The file size is ${Math.round(file.size / (1000 * 1000))}MB`
                            );
                            return;
                        }

                        setFieldValue(name, file);
                        setSelectedImage(file);
                    }

                    handleFileChange(event);
                }}
            />
            {label ? <h3>{label}</h3> : ''}
            <label htmlFor={name}>
                <Button
                    component='span'
                    variant='contained'
                    size='small'
                    color='primary'
                    style={{ padding: 8 }}
                    startIcon={<CloudUpload />}
                    title={titleText || label}
                    disabled={disabled}
                >
                    {titleBtn}
                </Button>
            </label>
            {selectedImage && showPreview ? (
                <img src={URL.createObjectURL(selectedImage)} style={{ maxWidth: '100%', marginTop: '10px' }} />
            ) : initImage ? (
                <img src={initImage} style={{ maxWidth: '100%', marginTop: '10px' }} />
            ) : (
                ''
            )}
            {initUrl ? (
                <a style={{ color: 'green', fontSize: '14px', marginTop: '10px' }} href={initUrl} target='_blank'>
                    {titleDownloadBtn || 'Open'}
                </a>
            ) : (
                ''
            )}
            {titleDocument && (
                <span style={{ color: 'green', fontSize: '14px', marginLeft: '10px' }}>{titleDocument}</span>
            )}
            <span style={{ color: '#f44336', fontSize: '13px' }}> {validationError ? validationError : ''} </span>
            <span style={{ color: '#f44336', fontSize: '13px' }}> {error && touched ? error : ''} </span>
            <span style={{ color: '#f44336', fontSize: '13px' }}> {error && touched ? true : false} </span>
        </GridFieldContainer>
    );
};

export const CheckboxElement = ({
    label,
    name,
    value,
    onChange,
    disabled = false,
    xl = 6,
    lg = 6,
    md = 6,
    sm = 6,
    $minWidth,
    LabelStyle = null,
}: CheckboxElementType) => {
    const handleLabelClick = (event) => {
        event.preventDefault();
    };

    return (<GridFieldContainer item xl={xl} lg={lg} md={md} sm={sm} xs={12} $minWidth={$minWidth}>
        <FormControlLabel
            disableTypography={!!LabelStyle}
            control={<Checkbox checked={value} name={name} color='primary' onChange={onChange} disabled={disabled} />}
            labelPlacement='start'
            label={
                LabelStyle ? (<LabelStyle onClick={handleLabelClick}>
                    {label}
                </LabelStyle>) : label
            }
        />
    </GridFieldContainer>);
};

export const LoadingElement = ({ label = 'Loading',xl = 6,  lg = 6, md = 6, sm = 6 }: LoadingElementType) => (
    <GridFieldContainer item xl={xl} lg={lg} md={md} sm={sm} xs={12}>
        <div className='f_loadingField'>
            <span className='label_loadingField'>{label}...</span>
        </div>
    </GridFieldContainer>
);

export const SelectAsyncPaginate = ({
    label,
    name,
    value,
    error,
    isDisabled = false,
    xl = 6,
    lg = 6,
    md = 6,
    sm = 6,
    options,
    isMulti = false,
    onChange,
    show = true,
    width,
    required = false,
    className = 'textFieldFormik',
    optionsPropertyLabel = 'name',
    optionsPropertyValue = 'id',
    height,
    loadOptions,
    isClearable = true,
    closeMenuOnSelect = true,
    textRequired
}: any) => {
    return (
        <GridFieldContainer
            item
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={12}
            className={className}
            visibility={show ? 'visible' : 'hidden'}
        >
            <AsyncPaginate
                id={name}
                isMulti={isMulti}
                closeMenuOnSelect={closeMenuOnSelect}
                name={name}
                isClearable={isClearable}
                placeholder={
                    required ? (
                        <span className='fieldRequired'>
                            <i>* </i> {label}
                        </span>
                    ) : (
                        label
                    )
                }
                isDisabled={isDisabled}
                styles={CustomStylesSelectAsyncPaginate(width, height, error)}
                options={options}
                classNamePrefix='my-custom-react-select'
                value={value}
                loadOptions={loadOptions}
                onChange={onChange}
                getOptionValue={(option) => option[optionsPropertyValue]}
                getOptionLabel={(option) => option[optionsPropertyLabel]}
                additional={{
                    page: 1
                }}
                menuPosition='fixed'
            />
            {error && (
                <p className='textRequired' id='serialNumber-helper-text'>
                    {textRequired}
                </p>
            )}
        </GridFieldContainer>
    );
};

export const SimpleSelectAsyncPaginate = ({
    label,
    name,
    value,
    error,
    isDisabled = false,
    options,
    isMulti = false,
    onChange,
    width,
    required = false,
    optionsPropertyLabel = 'name',
    optionsPropertyValue = 'id',
    height,
    loadOptions,
    isClearable = true,
    closeMenuOnSelect = true,
    textRequired,
    }: any) => {
        return (
            <Box>
                <AsyncPaginate
                    id={name}
                    isMulti={isMulti}
                    closeMenuOnSelect={closeMenuOnSelect}
                    name={name}
                    isClearable={isClearable}
                    placeholder={
                        required ? (
                            <span className='fieldRequired'>
                                <i>* </i> {label}
                            </span>
                        ) : (
                            label
                        )
                    }
                    isDisabled={isDisabled}
                    styles={CustomStylesSelectAsyncPaginate(width, height, error)}
                    options={options}
                    classNamePrefix='my-custom-react-select'
                    value={value}
                    loadOptions={loadOptions}
                    onChange={onChange}
                    getOptionValue={(option) => option[optionsPropertyValue]}
                    getOptionLabel={(option) => option[optionsPropertyLabel]}
                    additional={{
                        page: 1
                    }}
                    menuPosition='fixed'
                />
                {error && (
                    <p className='textRequired' id='serialNumber-helper-text'>
                        {textRequired}
                    </p>
                )}
            </Box>
        );
};
