import React from 'react';
import * as view from './PublicSurvey.view';
import * as type from './PublicSurvey.type';
import Wrapper from '../../helpers/wrapper';
// import { useParams } from 'react-router';
// import module

const PublicSurvey: React.FC<type.PublicSurveyProps> = (): JSX.Element => {
    // const { hash }: any = useParams();
    
    return (
        <view.PublicSurveyContent data-testid='PublicSurvey-testid' />
    );
};

export default Wrapper(PublicSurvey);