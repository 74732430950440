import React from 'react';
import * as style from './GlobalFilter.style';
import * as type from './GlobalFilter.type';
import { useAsyncDebounce } from 'react-table';
import { Search } from '@mui/icons-material';
import Translator from '../../../../../states/global/Translator';
import { InputAdornment, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
// import module

const GlobalFilterContent: React.FC<type.GlobalFilterProps> = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    title
}) => {
    const count = preGlobalFilteredRows;
    const [value, setValue] = React.useState<string | Record<string, unknown>[]>(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    const Trans = useRecoilValue(Translator);

    return (
        <style.GlobalFilterContent data-testid='GlobalFilterContent'>
            <TextField
                variant='outlined'
                size='small'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <Search />
                        </InputAdornment>
                    )
                }}
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${title} ${count} ${Trans('messages.t.records')}...`}
                style={{
                    borderRadius: '5px'
                }}
            />
        </style.GlobalFilterContent>
    );
};

export default GlobalFilterContent;
