import React from 'react';
import {
    AppBarContainer,
    ContainerBtnClose,
    TitlePage,
    UiModalContent,
    UiScrollbarsModal,
    UiToolbar
} from './UiModal.style';
import { UiModalViewProps } from './UiModal.type';
import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { getFullScreenModal } from '../../../helpers/getFullScreenModal';
// import module

export const UiModalView: React.FC<UiModalViewProps> = (props): JSX.Element => (
    <UiModalContent data-testid='UiModalContent'>
        <Dialog
            fullScreen={getFullScreenModal()}
            fullWidth={true}
            maxWidth={props.maxWidthModal || 'sm'}
            open={props.open}
            onClose={() => {
                props.closeModal();
            }}
            aria-labelledby='responsive-dialog-title'
        >
            <AppBarContainer>
                <UiToolbar>
                    <TitlePage> {props.title} </TitlePage>
                    <ContainerBtnClose>
                        <IconButton edge='end' color='inherit' onClick={() => props.closeModal()} aria-label='close'>
                            <Close />
                        </IconButton>
                    </ContainerBtnClose>
                </UiToolbar>
            </AppBarContainer>

            <DialogContent>
                <UiScrollbarsModal
                    autoHeight
                    autoHide
                    $minHeight={props.minHeight}
                    $maxHeight={props.maxHeight || 'calc(100vh - 75px)'}
                    renderThumbVertical={({ style, ...props }) => (
                        <div {...props} className='scrollYcustom' style={{ ...style }} />
                    )}
                >
                    {props.content}
                </UiScrollbarsModal>
            </DialogContent>

            {props.buttons && <DialogActions>{props.buttons}</DialogActions>}
        </Dialog>
    </UiModalContent>
);
