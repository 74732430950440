import React from 'react';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import { FleetInformationTableType } from './SiteSurvey.type';
import { IconButton } from '@mui/material';
import { ColumnsTable } from '../../types/common';
import { CellProps } from 'react-table';
import Delete from '@material-ui/icons/Delete';
import UiTable from '../Ui/UiTable/UiTable';

const typesVehicle = {
    haul_truck: 'Haul Truck',
    front_end_loader: 'Front End Loader',
    water_cart: 'Water Cart',
    wheel_dozer: 'Wheel Dozer',
    grader: 'Grader',
    articulated_dump_truck: 'Articulated Dump Truck',
    other_state_other: 'Other (state other)'
};

export const FleetInformationTable: React.FC<FleetInformationTableType> = ({ 
    handleRemoveItems, 
    items 
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const columnsItems: ColumnsTable[] = [
        {
            Header: 'Make/Model',
            accessor: 'model',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60
        },
        {
            Header: 'Type',
            accessor: 'type',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => typesVehicle[row.original.type]
        },
        {
            Header: 'Quantity',
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60
        },
        {
            Header: 'Utilisation (hrs/mth)',
            accessor: 'utilisation',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.utilisation || '---'
        },
        {
            Header: 'Avg/Max Speed',
            accessor: 'maxSpeed',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.maxSpeed || '---'
        },
        {
            Header: 'Type size',
            accessor: 'typeSize',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.typeSize || '---'
        },
        {
            Header: 'Front Life',
            accessor: 'frontLife',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.frontLife || '---'
        },
        {
            Header: 'Rear life',
            accessor: 'rearLife',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.rearLife || '---'
        },
        {
            Header: Trans('messages.t.actions'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'action',
            width: 40,
            minWidth: 40,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <>
                    <IconButton
                        size='small'
                        style={{ minWidth: 'auto', color: '#ef4430' }}
                        title={Trans('messages.t.remove')}
                        id={`btn_edit_${row.original.type}`}
                        onClick={() => handleRemoveItems(row.original, false)}
                        color='secondary'
                    >
                        <Delete />
                    </IconButton>
                </>
            )
        }
    ];

    return (
        <UiTable
            classesTable='containerDataTable'
            data={items}
            loading={false}
            hidePagination={true}
            hideForceRefresh={true}
            subtractSpace={380}
            noPagination={true}
            columns={columnsItems}
            hiddenColumns={[]}
            hideXlsExport={true}
        />
    );
};
