import { createStyles, makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const SurveyContentStyle = styled.div``;

export const useStyles = makeStyles(() =>
    createStyles({
        inputFilterColumn: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)'
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                paddingTop: '11px !important',
                paddingBottom: '11px !important'
            }
        }
    })
);

export const SurveyContainerModal = styled.div`
    padding-top: 15px;
`;
