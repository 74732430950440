import React, { useCallback, useState } from 'react';
import { SurveyContent } from './Survey.view';
import Wrapper from '../../helpers/wrapper';
import Translator from '../../states/global/Translator';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ColumnsTable } from '../../types/common';
import { 
    Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography 
} from '@mui/material';
import ApiSurvey from '../../api/ApiSurvey';
import { Surveys } from '../../states/component/Survey';
import { SurveyType } from '../../models/ApiSurvey.type';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import { CellProps } from 'react-table';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import { ContainerSelectFilter } from '../../styled/global.style';
import { useStyles } from './Survey.style';
import EmailsList from './EmailsList/EmailsList';
import { ContentCopy, ListAlt, Send } from '@mui/icons-material';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { Success, Warning } from '../Popup/Popup';
// import module

const apiSurvey = new ApiSurvey();

const statusSurvey = [
    {
        id: 'new',
        name: 'New'
    },
    {
        id: 'email_seen',
        name: 'Email Seen'
    },
    {
        id: 'in_progress',
        name: 'In Progress'
    },
    {
        id: 'in_acceptance',
        name: 'In Acceptance'
    },
    {
        id: 'accepted',
        name: 'Accepted'
    }
];

const Survey: React.FC = (): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const [open, setOpen] = useState<boolean>(false);
    const [idDetail, setIdDetail] = useState<number | null>(null);
    const setdata = useSetRecoilState<SurveyType[]>(Surveys);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const getDateMask = useRecoilValue(GetDateMask);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const classes = useStyles();

    const copyUrlToClipboard = (path = '') => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/${path}`.replace(/\/+/g, '/');
      
        navigator.clipboard.writeText(fullUrl)
        .then(() => {
            Success({
                text: `${Trans('messages.t.link_copied')}`
            });
        });
    };

    const getAll = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setdata([]);
            const data = await apiSurvey.getAll(page, limit, order, filter);
            setdata(data.items);
            setPageCount(data.paginator.pageCount);
            setTotal(data.paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

     const resendSurvey = async (id: number) => {
        Warning({
            text: `${Trans('messages.t.resending_survey')}`
        });
        await apiSurvey.resend(id).then(() => {
            Success({
                text: `${Trans('messages.t.survey_resent')}`
            });
        });
    };

    const showDetail = (Survey: SurveyType) => {
        setIdDetail(Survey.id);
        openModal();
    };

    const ActionsButtons = (original: SurveyType): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => showDetail(original)}>
                    <ListAlt />
                    <span>{Trans('messages.t.view_detail')}</span>
                </Typography>

                {original.status !== 'accepted' && (
                    <Typography 
                        className='dataTableActionsBtn'
                        onClick={() => resendSurvey(original.id)}
                    >
                        <Send />
                        <span>{Trans('messages.t.resend')}</span>
                    </Typography>
                )} 

                <Typography 
                    className='dataTableActionsBtn' 
                    onClick={() => copyUrlToClipboard(`public-survey/${original.token}`)}
                >
                    <ContentCopy />
                    <span>{Trans('messages.t.copy_public_link')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'Sent To',
            accessor: 'email',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 100,
            Cell: ({ row }: CellProps) => {
                return (
                    <EmailsList
                        id={row.original.id}
                        emails={row.original.email}
                    />
                );
            }
        },
        {
            Header: 'Name',
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 100
        },
        {
            Header: 'Send Date',
            accessor: 'invitedAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 100,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original.invitedAt || '---', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.invited_at_from')}
                            filterName='invitedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.invited_at_to')}
                            filterName='invitedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: 'Filling Date',
            accessor: 'acceptedAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 100,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original.acceptedAt || '---', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.send_date_from')}
                            filterName='sendDateFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.send_date_to')}
                            filterName='sendDateTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 100,
            Cell: ({ row }: CellProps) =>
                            Trans('messages.t.' + row.original.status.replaceAll(' ', '_').toLowerCase()),
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{ backgroundColor: 'white', padding: '0 5px 0 0' }}
                            >
                                {column.Header}
                            </InputLabel>
                            <Select
                                className={classes.inputFilterColumn}
                                value={column.filterValue?.value || ''}
                                onChange={(e) => {
                                    const value = !e.target.value
                                        ? undefined
                                        : {
                                            stats: column.Header,
                                            name: column.Header,
                                            humanValue: statusSurvey
                                                .filter((status) => e.target.value.includes(status.id))
                                                .map((status) => status.name),
                                            value: e.target.value
                                          };

                                    setFilter(column.id, value);
                                }}
                                input={<OutlinedInput label='Tag' />}
                                renderValue={(selected) =>
                                    statusSurvey
                                        .filter((status) => selected.includes(status.id))
                                        .map((status) => status.name)
                                        .join(', ')
                                }
                            >
                                {statusSurvey.map((status) => (
                                    <MenuItem key={status.id} value={status.id} style={{ padding: '0px' }}>
                                        <Checkbox
                                            checked={
                                                column.filterValue && column.filterValue.value
                                                    ? column.filterValue.value.includes(status.id)
                                                    : false
                                            }
                                        />
                                        <ListItemText primary={status.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            Cell: ({ row: { original } }: CellProps) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        }
    ];

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setTimeout(() => {
            idDetail && setIdDetail(null);
        }, 500);
    };

    return (
        <SurveyContent
            data-testid='Survey-testid'
            openModal={openModal}
            closeModal={closeModal}
            fetchData={getAll}
            setRefresh={setRefresh}
            columnsSurvey={columns}
            open={open}
            loading={loading}
            pageCount={pageCount}
            total={total}
            refresh={refresh}
            idDetail={idDetail}
        />
    );
};

export default Wrapper(Survey);