import React from 'react';
import * as view from './Survey.view';
import * as type from './Survey.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Survey:React.FC<type.SurveyProps> = (): JSX.Element => {
  return (
    <view.SurveyContent data-testid='Survey-testid'/>
  );  
};

export default Wrapper(Survey);