import { Button } from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import styled, { createGlobalStyle } from 'styled-components';
import Scrollbars from 'react-custom-scrollbars-2';

export const CreateOrderContent = styled.div``;

export const ShippingOrderbody = createGlobalStyle`
    .makeStyles-tableBody-18{
        max-height: 470px;
        overflow-y: auto;
    }
    .makeStyles-tableHeadCell-10{
        font-size: 16px !important;
    }
    .containerDataTable{
        min-height: 350px;
    }
`;
export const ButtonSuccess = withStyles(() => ({
    root: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    }
}))(Button);

export const useStyles = makeStyles(() =>
    createStyles({
        inputFilterColumn: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)'
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                paddingTop: '11px !important',
                paddingBottom: '11px !important'
            }
        }
    })
);

export const UiScrollbarsOrder = styled(Scrollbars)`
    height: auto !important;
    max-height: calc(100vh - 110px) !important;

    & > div:first-child {
        max-height: calc(100vh - 110px) !important;
    }
`;

export const FieldsetLeftContainer = styled.fieldset`
    height: auto;
    border-radius: 5px;
    padding-left: 0px;
`;

export const FieldsetContainer = styled.fieldset`
    min-height: 641px;
    height: auto;
    border-radius: 5px;

    @media (max-width: 1915px) {
        min-height: 641px;
    }
`;

export const ContainerRightItems = styled.fieldset`
    display: flex;

    @media (max-width: 768px) {
        display: block;
    }
`;

