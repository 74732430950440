import React from 'react';
import { SiteSurveyStepTwoForm, StepTwoViewProps } from './StepTwo.type';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { ListItemCustom, UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { AutocompleteElement, InputElement } from '../../FormElements';
// import module

export const StepTwoContent: React.FC<StepTwoViewProps> = ({
    valuesInitForm,
    activeStep,
    optionsSelect,
    handleBack,
    handleNext,
    handleSubmit
}): JSX.Element =>  {
    const Trans = useRecoilValue(Translator);

    const validationSchema = Yup.object().shape({
        cellularAvailable: Yup.string().required(Trans('messages.p.this_field_is_required')),
        cellularProvider: Yup.string(),
        cellularRestrictions: Yup.string().required(Trans('messages.p.this_field_is_required')),
        wifiAvailable: Yup.string().required(Trans('messages.p.this_field_is_required')),
        wifiInternet: Yup.string(),
        wifiFirewall: Yup.string(),
        wifiRouter: Yup.string(),
        wifiEthernet: Yup.string().required(Trans('messages.p.this_field_is_required')),
        dispatchSystem: Yup.string(),
        dispatchLink: Yup.string(),
        tmsSystem: Yup.string(),
        networkComment: Yup.string()
    });
    
    return  (
        <Formik
            initialValues={valuesInitForm}
            onSubmit={(values: SiteSurveyStepTwoForm, actions) => {
                handleSubmit(values, 2, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
        >
            {(propsF: FormikProps<SiteSurveyStepTwoForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm
                    // resetForm
                } = propsF;

                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            <Grid container spacing={2}>
                                {/* WI-FI NETWORK */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{Trans('messages.t.wifi_network')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Is there a site wide Wi-Fi network?</strong>
                                            {/* <strong>{Trans('messages.p.wifiAvailable')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiAvailable'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiAvailable}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiAvailable
                                                )}
                                                error={errors.wifiAvailable}
                                                touched={touched.wifiAvailable}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Does the Wi-Fi network have access to the internet?</strong>
                                            {/* <strong>{Trans('messages.p.wifiInternet')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiInternet'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiInternet}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiInternet
                                                )}
                                                error={errors.wifiInternet}
                                                touched={touched.wifiInternet}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={false}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>
                                                Is outbound data on the Wi-Fi network resticted by a firewall?
                                            </strong>
                                            {/* <strong>{Trans('messages.p.wifiFirewall')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiFirewall'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiFirewall}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiFirewall
                                                )}
                                                error={errors.wifiFirewall}
                                                touched={touched.wifiFirewall}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={false}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>
                                                Is there a Wi-Fi network router/switch on the truck?
                                            </strong>
                                            {/* <strong>{Trans('messages.p.wifiRouter')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiRouter'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiRouter}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiRouter
                                                )}
                                                error={errors.wifiRouter}
                                                touched={touched.wifiRouter}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={false}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>
                                                Is there a free ethernet port available on the truck’s router/switch?
                                            </strong>
                                            {/* <strong>{Trans('messages.p.wifiEthernet')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiEthernet'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiEthernet}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiEthernet
                                                )}
                                                error={errors.wifiEthernet}
                                                touched={touched.wifiEthernet}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                {/* CELLULAR NETWORK */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{Trans('messages.t.cellular_network')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Are there GSM cellular networks available?</strong>
                                            {/* <strong>{Trans('messages.p.cellularAvailable')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='cellularAvailable'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.cellularAvailable}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.cellularAvailable
                                                )}
                                                error={errors.cellularAvailable}
                                                touched={touched.cellularAvailable}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Which GSM provider has the best coverage?</strong>
                                            {/* <strong>{Trans('messages.p.cellularProvider')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                           <InputElement
                                                label=''
                                                name='cellularProvider'
                                                value={values.cellularProvider}
                                                error={errors.cellularProvider}
                                                touched={touched.cellularProvider}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={false}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Are there any GSM restrictions on site?</strong>
                                            {/* <strong>{Trans('messages.p.cellularRestrictions')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='cellularRestrictions'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.cellularRestrictions}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.cellularRestrictions
                                                )}
                                                error={errors.cellularRestrictions}
                                                touched={touched.cellularRestrictions}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>
                                
                                {/* DISPATCH SYSTEM */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{Trans('messages.t.dispatch_system')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Are there GSM cellular networks available?</strong>
                                            {/* <strong>{Trans('messages.p.dispatchSystem')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <InputElement
                                                label=''
                                                name='dispatchSystem'
                                                value={values.dispatchSystem}
                                                error={errors.dispatchSystem}
                                                touched={touched.dispatchSystem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={false}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Does iTrack need to link with this dispatch system?</strong>
                                            {/* <strong>{Trans('messages.p.dispatchLink')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='dispatchLink'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.dispatchLink}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.dispatchLink
                                                )}
                                                error={errors.dispatchLink}
                                                touched={touched.dispatchLink}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={false}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                {/* TYRE MANAGEMENT SYSTEM (TMS) */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{Trans('messages.t.tyre_management_system (tms)')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>Which Tyre Management System (TMS) is used on site?</strong>
                                            {/* <strong>{Trans('messages.p.tmsSystem')}</strong> */}
                                        </Grid>
                                        <Grid item md={4}>
                                            <InputElement
                                                label=''
                                                name='tmsSystem'
                                                value={values.tmsSystem}
                                                error={errors.tmsSystem}
                                                touched={touched.tmsSystem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={false}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={12}><Divider /></Grid>
                                
                                <InputElement
                                    label={Trans('messages.t.comment')}
                                    name='networkComment'
                                    value={values.networkComment}
                                    error={errors.networkComment}
                                    touched={touched.networkComment}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    rows={3}
                                    multiline
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                />
                            </Grid>
                        </UiScrollbarsSiteSurvey>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pb: 2 }}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} variant='contained' size='small' sx={{ mr: 1 }}>
                                    Back
                                </Button>
                            )}
                            {activeStep < 4 && (
                                <Button 
                                    onClick={handleNext} 
                                    sx={{ mr: 1 }} 
                                    variant='contained'
                                    size='small'
                                    disabled={
                                        Object.keys(touched).length === 0 || 
                                        Object.keys(touched).length > 0 && Object.keys(errors).length > 0
                                    }
                                >
                                    Next
                                </Button>
                            )}
                            <Button 
                                onClick={() => {
                                    submitForm();
                                }} 
                                sx={{ mr: 1 }} 
                                variant='contained'
                                size='small'
                                disabled={isSubmitting}
                            >
                                {activeStep === 4 ? 'Finish' : 'Save'} 
                            </Button>
                        </Box>
                    </>
                );
            }}
        </Formik>
    );
};