import { Grid } from '@mui/material';
import styled from 'styled-components';

export const CreateInvitacionContent = styled.div``;

export const ButtonsActionContainer = styled.div`
    margin: 10px;
`;

export const GridCustomer = styled(Grid)`
    width: 100%;
    padding: 10px;
`;