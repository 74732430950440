import Device from '../pages/Device/Device';
import Boxes from '../pages/Boxes/Boxes';
import CreateOrder from '../pages/CreateOrder/CreateOrder';
import GenericItems from '../pages/GenericItems/GenericItems';
import CurrentOrdersInbound from '../pages/CurrentOrdersInbound/CurrentOrdersInbound';
import CurrentOrdersOutbound from '../pages/CurrentOrdersOutbound/CurrentOrdersOutbound';
import OrderList from '../pages/OrderList/OrderList';
import Dashboard from '../pages/Dashboard/Dashboard';
import ImportOrder from '../pages/ImportOrder/ImportOrder';
import TrentShipment from '../pages/TrentShipment/TrentShipment';
import OrderHistoryInbound from '../pages/OrderHistoryInbound/OrderHistoryInbound';
import OrderHistoryOutbound from '../pages/OrderHistoryOutbound/OrderHistoryOutbound';
import Profile from '../pages/Profile/Profile';
import Roles from '../pages/Roles/Roles';
import Sensor from '../pages/Sensor/Sensor';
import Shipper from '../pages/Shipper/Shipper';
import StockLocation from '../pages/StockLocation/StockLocation';
// import LocationGroup from '../pages/LocationGroup/LocationGroup';
import Tablet from '../pages/Tablet/Tablet';
import User from '../pages/User/User';
import Sku from '../pages/Sku/Sku';
import ItemsReport from '../pages/ItemsReport/ItemsReport';
import { IRoute } from '../components/MainMenu/MainMenu.type';
import Reports from '../pages/Reports/Reports';
import CustomerAddress from '../pages/CustomerAddress/CustomerAddress';
import ItemWithSerialNumber from '../pages/ItemWithSerialNumber/ItemWithSerialNumber';
import Survey from '../pages/Survey/Survey';

const routes: IRoute[] = [
    {
        path: '/app',
        name: 'Dashboard Page',
        component: Dashboard,
        exact: true,
        allowedroles: ['']
    },
    {
        path: '/app/order/list',
        name: 'All orders',
        component: OrderList,
        exact: true,
        allowedroles: ['ID_5']
    },
    {
        path: '/app/current-order/inbound',
        name: 'Current order',
        component: CurrentOrdersInbound,
        exact: true
    },
    {
        path: '/app/current-order/outbound',
        name: 'Current order',
        component: CurrentOrdersOutbound,
        exact: true
    },
    {
        path: '/app/history-order/inbound',
        name: 'History order',
        component: OrderHistoryInbound,
        exact: true
    },
    {
        path: '/app/history-order/outbound',
        name: 'History order',
        component: OrderHistoryOutbound,
        exact: true
    },
    {
        path: '/app/create-order',
        name: 'Create Order',
        component: CreateOrder,
        exact: true,
        allowedroles: ['access_create_order_page']
    },
    {
        path: '/app/stock-location',
        name: 'Stock Location',
        component: StockLocation,
        exact: true,
        allowedroles: ['access_stock_location_page']
    },
    // {
    //     path: '/app/location-group',
    //     name: 'Location Group',
    //     component: LocationGroup,
    //     exact: true,
    //     allowedroles: ['access_stock_location_page']
    // },
    {
        path: '/app/shipper',
        name: 'Shipper',
        component: Shipper,
        exact: true,
        allowedroles: ['access_shipper_page']
    },
    {
        path: '/app/trent-shipments',
        name: 'Trent Shipments',
        component: TrentShipment,
        exact: true,
        allowedroles: ['access_trent_sensor_import']
    },
    {
        path: '/app/items/sensors',
        name: 'Sensors',
        component: Sensor,
        exact: true,
        allowedroles: ['access_sensors_page']
    },
    {
        path: '/app/items/with-serial-number',
        name: 'ItemWithSerialNumber',
        component: ItemWithSerialNumber,
        exact: true,
        allowedroles: ['access_items']
    },
    {
        path: '/app/sku',
        name: 'SKU',
        component: Sku,
        exact: true,
        allowedroles: ['access_sku_page']
    },
    {
        path: '/app/boxes',
        name: 'Boxes',
        component: Boxes,
        exact: true,
        allowedroles: ['access_box_page']
    },
    {
        path: '/app/items/tablets',
        name: 'Tablets',
        component: Tablet,
        exact: true,
        allowedroles: ['access_tablets_page']
    },
    {
        path: '/app/settings/users',
        name: 'Users',
        component: User,
        exact: true,
        allowedroles: ['access_users_page']
    },
    {
        path: '/app/settings/customer-addresses',
        name: 'CustomerAddress',
        component: CustomerAddress,
        exact: true,
        allowedroles: ['create_and_edit_customer_address']
    },
    {
        path: '/app/items/generic-items',
        name: 'Generic-items',
        component: GenericItems,
        exact: true,
        allowedroles: ['access_create_stock_page']
    },
    {
        path: '/app/items/hubs',
        name: 'Hubs',
        component: Device,
        exact: true,
        allowedroles: ['access_hubs_page']
    },
    {
        path: '/app/settings/roles',
        name: 'Roles',
        component: Roles,
        exact: true,
        allowedroles: ['access_roles_page']
    },
    {
        path: '/app/profile',
        name: 'Profile',
        component: Profile,
        exact: true
    },
    {
        path: '/app/import-order',
        name: 'Import Order',
        component: ImportOrder,
        exact: true,
        allowedroles: ['access_trent_sensor_import']
    },
    {
        path: '/app/reports/items',
        name: 'Reports Items',
        component: ItemsReport,
        exact: true,
        allowedroles: ['access_reports_items']
    },
    {
        path: '/app/quicksight-reports',
        name: 'Reports',
        component: Reports,
        exact: true,
        allowedroles: ['access_quicksight_reports']
    },
    {
        path: '/app/survey',
        name: 'Survey',
        component: Survey,
        exact: true
        // allowedroles: ['access_quicksight_reports']
    }
];

export default routes;
