import styled from 'styled-components';

export const PublicSurveyContentStyle = styled.div`
    .logo-stock {
        display: block;
        margin: 0 auto;
        .logo-survey {
            width: 150px;
        }
    }
`;