import { ShippingOrderStatusIdsEnum } from '../components/CurrentOrdersOutbound/CurrentOrdersOutbound.type';
import { Model } from '../models/ApiShippingOrders.type';
import { SurveyType } from '../models/ApiSurvey.type';

export const resendOrderButtonCanBeDisplayed = (shippingOrder: Model): boolean => {
    let show = false;

    if (shippingOrder.locationTo && shippingOrder.locationTo.reshipmentEnabled) {
        if (
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.IN_TRANSIT ||
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.IN_DISPUTE ||
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.DELIVERED ||
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.PARTIALLY_DELIVERED ||
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.CANCELED
        ) {
            show = true;
        }
    }
    return show;
};

export const checkSurvey = (surveyData: SurveyType | null, propertyName: string): string => {
    if (!surveyData || !surveyData.customerOnboarding || !surveyData.customerOnboarding.data) {
      return '';
    }
  
    if (propertyName in surveyData.customerOnboarding.data) {
      return '' + surveyData.customerOnboarding.data[propertyName];
    }

    return '';
};