import React from 'react';
import { XlsExportContent } from './XlsExport.style';
import * as type from './XlsExport.type';
import Wrapper from '../../../../../helpers/wrapper';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { FileDownload, FileDownloadOff } from '@mui/icons-material';

import ExcelJS from 'exceljs';

let _requetsPromises = {};

const toDataURL = (url) => {
    if (_requetsPromises[url]) {
        return _requetsPromises[url];
    }

    _requetsPromises[url] = new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.onloadend = function () {
                resolve({ base64Url: reader.result });
            };
        };
        xhr.onerror = function () {
            resolve({ base64Url: null });
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });

    return _requetsPromises[url];
};

const XlsExport: React.FC<type.XlsExportProps> = (props): JSX.Element => {
    const [loading, setLoading] = React.useState(false);

    const exportExcelFile = async () => {
        setLoading(true);
        _requetsPromises = {};

        let data = props.table.rows.map(function (row) {
            return row.original;
        });

        const fetchDataExport = props.fetchDataExport;

        if (fetchDataExport) {
            data = await fetchDataExport(props.table?.state?.sortBy, props.currentFilters, props.extraFiltering);
        }

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Sheet 1');
        const columns: Array<any> = [];

        sheet.properties.defaultRowHeight = 50;
        const ignoreColumns = props.ignoreColumns || [];
        const ignoreTypes = props.ignoreTypes || [];

        props.table.visibleColumns.forEach((column) => {
            const header = column.Header instanceof Function ? column.Header() : column.Header;

            if(props.forCustomer && column.id.includes('sku') && !column.id.includes('skuCustomer')){
                return;
            }
            if(!props.forCustomer && column.id.includes('sku') && column.id.includes('skuCustomer')){
                return;
            }

            if (
                !column.disableExport &&
                header &&
                column.id !== 'action' &&
                ignoreColumns.indexOf(column.id) === -1 &&
                ignoreTypes.indexOf(column.exportType) === -1
            ) {
                columns.push(column);
            }
        });

        sheet.columns = columns.map((column) => {
            return {
                header: column.Header,
                key: column.id,
                width: column.id === 'id' ? 10 : 25
            };
        });

        data.map(async (row) => {
            const rowValues: any = {};

            for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
                const column = columns[columnIndex];
                const path = column.id;
                const value = path.split('.').reduce((acc, key) => acc[key] ?? '', row);

                rowValues[column.id] = Array.isArray(value) ? '' : value;

                if (column?.exportType !== 'image' && column?.Export) {
                    rowValues[column.id] = column?.Export({ row: { original: row } });
                }
            }

            sheet.addRow(rowValues);
            const lastRow = sheet.lastRow;
            if (lastRow) {
                lastRow.height = 50;
            }
        });

        // Add images to rows
        const promise = Promise.all(
            data.map(async (row, index) => {
                const rowNumber = index + 1;

                for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
                    const column = columns[columnIndex];

                    if (column?.exportType === 'image' && column?.Export) {
                        const thumbnail = column?.Export({ row: { original: row } });

                        if (thumbnail) {
                            const result: any = await toDataURL(thumbnail);

                            if (result.base64Url) {
                                const imageId2 = workbook.addImage({
                                    base64: result.base64Url,
                                    extension: 'jpeg'
                                });

                                sheet.addImage(imageId2, {
                                    tl: {
                                        col: columnIndex,
                                        row: rowNumber
                                    },
                                    ext: {
                                        width: 50,
                                        height: 50
                                    }
                                });
                            }
                        }
                    }
                }
            })
        );

        promise.then(() => {
            columns.forEach((column, index) => {
                const priceCol = sheet.getColumn(index + 1);

                // iterate over all current cells in this column
                priceCol.eachCell((cell) => {
                    sheet.getCell(cell?.address).alignment = {
                        vertical: 'middle',
                        horizontal: 'left'
                    };
                });
            });

            workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                const tableTitle = props.table.title;

                anchor.href = url;
                anchor.download = tableTitle.charAt(0).toUpperCase() + tableTitle.slice(1) + '.xlsx';
                anchor.click();

                window.URL.revokeObjectURL(url);
                setLoading(false);
            });
        });
    };

    return (
        props.textFormDisplay ? <Typography onClick={() => exportExcelFile()} style={{display: 'flex'}}>{props.offIcon ? <FileDownloadOff /> : <FileDownload />} {props.tooltip}</Typography> :
        <div style={{ display: 'contents' }}>
            <Tooltip title={props.tooltip || ''} aria-label={props.tooltip}>
                <XlsExportContent data-testid='XlsExportContent'>
                    <IconButton onClick={() => exportExcelFile()}>
                        {props.offIcon ? <FileDownloadOff /> : <FileDownload />}
                    </IconButton>
                </XlsExportContent>
            </Tooltip>
            {loading ? (
                <div className='backdrop'>
                    <div className='upload-loader' style={{ minHeight: '180px', display: 'contents' }}>
                        <div
                            className='p-loading-spinner piano-spinner'
                            style={{ height: '140px', marginTop: '40px' }}
                        ></div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Wrapper(XlsExport);
