import { AxiosResponse } from 'axios';
import PortalApi from '../helpers/api/portalApi';
import { SurveyResponseType, SurveyType, SurveyWithPagnator } from '../models/ApiSurvey.type';
import { formatParametersToGetPagination } from '../helpers/converter';

export default class ApiSurvey extends PortalApi<SurveyType> {
    route = 'customer-onboarding';

    create = async <T, B, R = AxiosResponse<T>>(data: B, version = 'v1'): Promise<R> => {
        return await this.CREATE(`${version}/${this.route}-invitation`, data);
    };

    saveStep = async <T, B, R = AxiosResponse<T>>(idOrToken: string | number, data: B): Promise<R> => {
        const path = typeof idOrToken === 'string'
            ? `v1/public/${this.route}/token/${idOrToken}`
            : `v1/${this.route}/invitation/${idOrToken}`;

        return await this.CREATE(path, data);
    };

    emailSeen = async <T, R = AxiosResponse<T>>(token: string): Promise<R> => {
        return await this.PATCH(`v1/public/${this.route}-invitation/token/${token}/seen`);
    };

    //POST {{base_url}}/v1/customer-onboarding-invitation/1/resend
    resend = async <T, R = AxiosResponse<T>>(id: number): Promise<R> => {
        return await this.CREATE(`v1/${this.route}-invitation/${id}/resend`);
    };

    getAll = async (page, limit, order, filter): Promise<SurveyWithPagnator> => {
        return await this.getTable<SurveyType>(
            '-invitation',
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getByTokenOrId = async (idOrToken: string | number): Promise<SurveyResponseType> => {
        if(typeof idOrToken === 'string') {
            return await this.getTemporal(`-invitation/token/${idOrToken}`, 'v1/public');
        }
        
        return await this.getTemporal(`-invitation/${idOrToken}`, 'v1');
    };
}