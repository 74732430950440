import React from 'react';
import { SubTableViewProps } from './SubTable.type';
import { SubTableView } from './SubTable.style';
import { Box, Link, Paper, TableContainer } from '@mui/material';
import UiTable from '../../UiTable';
import Translator from '../../../../../states/global/Translator';
import { useRecoilValue } from 'recoil';

const SubTableContainer: React.FC<SubTableViewProps> = ({
    data, columnsSubTable, loading, boxId, openBoxItemModal
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <SubTableView>
            <TableContainer component={Paper} sx={{ pt: 1 }}>
                <UiTable
                    classesTable='containerDataTable'
                    data={data}
                    loading={loading}
                    hidePagination={true}
                    hideFeSidePagination={true}
                    hideForceRefresh={true}
                    noPagination={true}
                    columns={columnsSubTable}
                    hiddenColumns={[]}
                    globalFilter={false}
                    hideXlsExport={true}
                    hideTableActions={true}
                />
                {data.length > 10 ? (
                    <Box sx={{ width: '94%', textAlign: 'end', mb: 1 }}>
                        <Link
                            component='a'
                            sx={{ fontWeight: 600, cursor: 'pointer' }}
                            color='#038a9e'
                            variant='body2'
                            onClick={() => {
                                openBoxItemModal(boxId);
                            }}
                            data-testid='SubTableView-ButtonLink-SeeMore'
                        >
                            {Trans('messages.t.see_more')}...
                        </Link>
                    </Box>
                ) : <></>}
            </TableContainer>
        </SubTableView>
    );
};

export default SubTableContainer;
