import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as Popup from '../../components/Popup';
import 'react-toastify/dist/ReactToastify.css';

const Cookies = document.cookie.split(';').map((cookie) => {
    const sCookie = cookie.split('=');
    sCookie[0] = sCookie[0].trim();
    return sCookie;
});

let JWT = Cookies.find((cookie) => cookie[0] === 'authToken');
JWT = JWT ? JWT : ['1', '2'];

const NEW_BACKEND_API = process.env.REACT_APP_API_URL || 'https://itrack-api.atms.dev/api';

const instance = axios.create({
    baseURL: NEW_BACKEND_API,
    headers: { Accept: 'application/json', Authorization: `Bearer ${JWT[1]}` }
});

instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        return config;
    },
    (error: string) => {
        return window.Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (config: AxiosResponse) => {
        return config;
    },
    (error: AxiosError<unknown>) => {
        Popup.Error({
            message: error.response?.statusText ? error.response.statusText : '',
            url: error.response?.config.url ? error.response.config.url : '',
            status: error.response ? error.response?.status : 0
        });
        return window.Promise.reject(error);
    }
);

export const get = async (url: string, params?: object): Promise<object> => {
    return await instance.get(url, { params });
};

export const create = async (url: string, params?: object): Promise<object> => {
    return await instance.post(url, params);
};

export const update = async (url: string, params?: object): Promise<object> => {
    return await instance.put(url, params);
};

export const remove = async (url: string, params?: object): Promise<object> => {
    return await instance.delete(url, params);
};

export const patch = (url: string, params?: object): Promise<object> => {
    return instance.patch(url, params);
};

export class Api {
    instance: AxiosInstance;
    showMessaggeError = true;

    constructor(config: AxiosRequestConfig) {
        this.instance = axios.create(config);
        this.setHTTPClient(this.instance);
        this.SUCCESS.bind(this);
        this.ERROR.bind(this);
    }

    setHTTPClient = (instance: AxiosInstance): void => {
        instance.interceptors.request.use(
            (param: AxiosRequestConfig) => {
                return {
                    ...param,
                    headers: {
                        ...param.headers,
                        Authorization: `Bearer ${this.getToken()}`
                    }
                };
            },
            (error: AxiosError<unknown>) => {
                return window.Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (param: AxiosResponse) => {
                return param;
            },
            (error: AxiosError<unknown>) => {
                if (error.response) {
                    if (this.showMessaggeError) {
                        const { message }: string | any = error.response.data;
                        if (message) {
                            if (typeof message === 'string' && message !== null) {
                                Popup.Errors({
                                    text: message
                                });
                            } else {
                                if (message.length) {
                                    Popup.Errors({
                                        text: message,
                                        body: message.messages,
                                        multipleMessage: true
                                    });
                                }
                            }
                        }
                    }
                } else {
                    Popup.Error({
                        message: 'Not Connected',
                        url: '',
                        status: 500
                    });
                }

                return window.Promise.reject(error);
            }
        );
    };

    getToken = (): string => {
        const token: RegExpMatchArray | null | string = document.cookie.replace(
            /(?:(?:^|.*;\s*)authToken\s*=\s*([^;]*).*$)|^.*$/,
            '$1'
        );

        if (token) {
            return token;
        }
        return '';
    };

    HEAD = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.head(url, config);
    };

    OPTIONS = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.options(url, config);
    };

    REQUEST = <T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> => {
        return this.instance.request(config);
    };

    GET = <T, R = AxiosResponse<T>>(url: string, params?: AxiosRequestConfig): Promise<R> => {
        return this.instance.get(url, { params });
    };

    GET_FILE = <T, R = AxiosResponse<T>>(url: string, params?: AxiosRequestConfig): Promise<R> => {
        return this.instance.get(url, params);
    };

    CREATE = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.post(url, data, config);
    };

    UPDATE = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.put(url, data, config);
    };

    PATCH = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.patch(url, data, config);
    };

    DELETE = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.delete(url, config);
    };

    SUCCESS<T>(response: AxiosResponse<T>): T {
        return response.data;
    }

    SUCCESS_FILE<T>(response: AxiosResponse<T>): AxiosResponse<T> {
        return response;
    }

    ERROR<T>(error: AxiosError<T>): void {
        throw error;
    }
}
