import React from 'react';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { ISignUpForm, SkuFormik as SkuFormikType } from './Sku.type';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import { AutocompleteElement, CheckboxElement, InputElement, InputFileElement } from '../FormElements';
import Translator from '../../states/global/Translator';
import { GetOptionLabel } from '../../types/common';

export const SkuFormik: React.FC<SkuFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const Formschema = Yup.object({
        name: Yup.string().trim().min(3, 'Sku name is Too Short.').required(Trans('messages.p.this_field_is_required')),
        customerLabel: Yup.string()
            .trim()
            .min(3, 'Customer label is Too Short.')
            .required(Trans('messages.p.this_field_is_required')),
        skuCode: Yup.string()
            .trim()
            .min(3, 'Sku code is Too Short.')
            .required(Trans('messages.p.this_field_is_required')),
        itemType: Yup.string().required(Trans('messages.p.this_field_is_required')),
        skuCodeCustomer: Yup.string().required(Trans('messages.p.this_field_is_required'))
    });

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='inicial' direction='row'>
                                <InputElement
                                    label={`${Trans('messages.t.code')}  Sku`}
                                    name='skuCode'
                                    value={values.skuCode}
                                    error={errors.skuCode}
                                    touched={touched.skuCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // autoFocus={true}
                                    required={true}
                                />

                                <InputElement
                                    label={Trans('messages.t.name')}
                                    name='name'
                                    value={values.name}
                                    error={errors.name}
                                    touched={touched.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                />

                                <InputElement
                                    label={Trans('messages.t.sku_visible_for_customer')}
                                    name='skuCodeCustomer'
                                    value={values.skuCodeCustomer}
                                    error={errors.skuCodeCustomer}
                                    touched={touched.skuCodeCustomer}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                />

                                <InputElement
                                    label={Trans('messages.t.name_visible_for_customer')}
                                    name='customerLabel'
                                    value={values.customerLabel}
                                    error={errors.customerLabel}
                                    touched={touched.customerLabel}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                />

                                <AutocompleteElement
                                    label={Trans('messages.t.type')}
                                    name='itemType'
                                    value={values.itemType}
                                    error={errors.itemType}
                                    touched={touched.itemType}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    required={true}
                                    disabled={values.existItems}
                                    options={props.skuType ? props.skuType : []}
                                    getOptionLabel={(option: GetOptionLabel): string => {
                                        if (option && option.type) {
                                            return Trans(`messages.t.${option.type}`);
                                        }
                                        return '';
                                    }}
                                    defaultValue={
                                        props.skuType &&
                                        props.skuType.find(
                                            (locationType) => `${locationType.id}` === `${values.itemType}` || ''
                                        )
                                    }
                                    optionsPropertyLabel='type'
                                    optionsPropertyValue='id'
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />

                                <CheckboxElement
                                    label={Trans('messages.t.archived')}
                                    name='archived'
                                    value={values.archived}
                                    onChange={handleChange}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />

                                <CheckboxElement
                                    label={Trans('messages.t.with_serial_numbers')}
                                    name='withSerialNumber'
                                    value={values.withSerialNumber}
                                    onChange={handleChange}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                />

                                <InputElement
                                    label={Trans('messages.t.length_mm')}
                                    name='lengthMm'
                                    value={values.lengthMm}
                                    error={errors.lengthMm}
                                    touched={touched.lengthMm}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value || 0);
                                        setFieldValue('lengthMm', value);
                                        setFieldValue('volume', (values.heightMm || 0) * (values.widthMm || 0) * value);
                                    }}
                                    onBlur={handleBlur}
                                    required={false}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />

                                <InputElement
                                    label={Trans('messages.t.height_mm')}
                                    name='heightMm'
                                    value={values.heightMm}
                                    error={errors.heightMm}
                                    touched={touched.heightMm}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value || 0);
                                        setFieldValue('heightMm', value);
                                        setFieldValue('volume', value * (values.widthMm || 0) * (values.lengthMm || 0));
                                    }}
                                    onBlur={handleBlur}
                                    required={false}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />

                                <InputElement
                                    label={Trans('messages.t.width_mm')}
                                    name='widthMm'
                                    value={values.widthMm}
                                    error={errors.widthMm}
                                    touched={touched.widthMm}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value || 0);
                                        setFieldValue('widthMm', value);
                                        setFieldValue(
                                            'volume',
                                            (values.heightMm || 0) * value * (values.lengthMm || 0)
                                        );
                                    }}
                                    onBlur={handleBlur}
                                    required={false}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />

                                <InputElement
                                    label={Trans('messages.t.weight_kg')}
                                    name='weightKg'
                                    value={values.weightKg}
                                    error={errors.weightKg}
                                    touched={touched.weightKg}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />

                                <InputElement
                                    label={Trans('messages.t.volume')}
                                    name='volume'
                                    value={values.volume}
                                    onChange={handleChange}
                                    disabled={true}
                                    required={false}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />
                            </Grid>
                            <Grid container justifyContent='inicial' direction='row'>
                                <InputFileElement
                                    label={''}
                                    name='itemImage'
                                    accept='.jpg,.jpeg,.png'
                                    error={errors.itemImage}
                                    titleDocument={''}
                                    initImage={props.imageUrl}
                                    titleBtn={Trans('messages.t.select_image')}
                                    touched={touched.itemImage}
                                    maxSizeKb={1000}
                                    setFieldValue={async (field, file) => {
                                        const base64 = await getBase64(file);
                                        setFieldValue(field, base64);
                                    }}
                                    handleFileChange={handleChange}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />

                                <InputFileElement
                                    label={''}
                                    name='itemSpecification'
                                    accept='.pdf'
                                    error={errors.itemSpecification}
                                    titleDocument={''}
                                    initUrl={props.specificationUrl}
                                    titleDownloadBtn={Trans('messages.t.open')}
                                    titleBtn={Trans('messages.t.product_specification')}
                                    touched={touched.itemSpecification}
                                    setFieldValue={async (field, file) => {
                                        const base64 = await getBase64(file);
                                        setFieldValue(field, base64);
                                    }}
                                    showPreview={false}
                                    handleFileChange={handleChange}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
