import React from 'react';
import * as style from './CreateInvitation.style';
import { CreateInvitacionViewProps, CreateInvitationForm } from './CreateInvitation.type';
import { Alert, Autocomplete, Button, Grid, TextField } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Translator from '../../../states/global/Translator';
import { useRecoilValue } from 'recoil';
import { AutocompleteElement, ChipsAutocompleteElement, InputElement } from '../../FormElements';
import { ButtonsActionContainer, GridCustomer } from './CreateInvitation.style';
import { LoadingButton } from '@mui/lab';
import { Loading } from '../../../helpers/Loading';
// import module

export const CreateInvitationContent: React.FC<CreateInvitacionViewProps> = ({
    valuesInitForm,
    regions,
    customers,
    timeZones,
    loadingData,
    handleSubmit
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const Formschema = Yup.object({
        name: Yup.string().required(Trans('messages.p.this_field_is_required')),
        email: Yup.string()
                .required(Trans('messages.p.this_field_is_required'))
                .test('emailsValidation', Trans('messages.p.please_enter_valid_email'), function (value) {
                    if (!value) {
                        return true;
                    }
                    const emailSchema = Yup.array().of(Yup.string().email());
                    const emails = value.split(';').map((email) => email.trim());
    
                    return emailSchema.isValidSync(emails);
                })
    });

    if (loadingData) return <Loading />;

    return (
        <style.CreateInvitacionContent data-testid='CreateInvitacionContent'>
            <Formik
                initialValues={valuesInitForm}
                onSubmit={(values: CreateInvitationForm, actions) => {
                    handleSubmit(values, actions.resetForm, actions.setSubmitting);
                }}
                validationSchema={Formschema}
            >
                {(propsF: FormikProps<CreateInvitationForm>) => {
                    const {
                        touched,
                        errors,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        values,
                        isSubmitting,
                        submitForm,
                        resetForm
                    } = propsF;

                    return (
                        <Form>
                            <Grid container direction='row'>
                                <Alert severity='info'>
                                    Upon submitting the survey,
                                    the customer will receive a unique link to access and answer the survey.
                                </Alert>
                                <ChipsAutocompleteElement
                                    label={Trans('messages.t.email')}
                                    name='email'
                                    value={(values.email || '')
                                        .split(',')
                                        .filter((email) => email !== '')}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChange={(e, value: string[]) => {
                                        values.email = value.join(',');
                                        setFieldValue('email', value.join(','));
                                    }}
                                    onBlur={handleBlur}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    required={true}
                                />
                                <InputElement
                                    label={Trans('messages.t.name')}
                                    name='name'
                                    value={values.name}
                                    error={errors.name}
                                    touched={touched.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    required={true}
                                />
                                <GridCustomer item lg={6} md={6} sm={12}>
                                    <Autocomplete
                                        id='region'
                                        fullWidth
                                        options={regions ?? []}
                                        onChange={(e, value) => {
                                            setFieldValue('region', value ? value.id : '');
                                        }}
                                        size='small'
                                        getOptionLabel={(option) =>
                                            option.translationString
                                                ? option.translationString
                                                    .replace('_', ' ')
                                                    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
                                                : ''
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                value={values.region}
                                                name='region'
                                                {...params}
                                                label={Trans('messages.t.region')}
                                                variant='outlined'
                                            />
                                        )}
                                    />
                                </GridCustomer>
                                <AutocompleteElement
                                    label={Trans('messages.t.customer')}
                                    name='customer'
                                    size='small'
                                    value={values.region}
                                    options={customers ?? []}
                                    onChange={(e, value) => {
                                        setFieldValue('customer', value ? value.id : '');
                                    }}
                                    optionsPropertyLabel='name'
                                    optionsPropertyValue='id'
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                />
                                <AutocompleteElement
                                    label={Trans('messages.t.timezone')}
                                    name='timezone'
                                    size='small'
                                    value={values.timezone}
                                    options={timeZones ?? []}
                                    onChange={(e, value) => {
                                        setFieldValue('timezone', value ? value.id : '');
                                    }}
                                    optionsPropertyLabel='timezoneName'
                                    optionsPropertyValue='id'
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                />
                            </Grid>
                            <ButtonsActionContainer>
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='secondary'
                                    onClick={() => resetForm()}
                                >
                                    {Trans('messages.t.reset')}
                                </Button>
                                &nbsp; &nbsp;
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    type='button'
                                    loading={isSubmitting}
                                    onClick={() => {
                                        submitForm();
                                    }}
                                >
                                    {Trans('messages.t.send')}
                                </LoadingButton>
                            </ButtonsActionContainer>
                        </Form>
                    );
                }}
            </Formik>
        </style.CreateInvitacionContent>
    );
};